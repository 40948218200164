import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-selection-icon',
  templateUrl: './single-selection-icon.component.html',
  styleUrls: ['./single-selection-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectionIconComponent implements OnInit {

  @Input()
  @HostBinding('class.selected')
  selected = false;

  constructor() { }

  ngOnInit(): void {
  }

}
