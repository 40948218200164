import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { PageLoaderFacade } from '@library/store/page-loader/page-loader.facade';
import { isDefined } from '@library/utils/helpers/store/is-defined';
import { firstValueFrom, share } from 'rxjs';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  home$ = this.homesFacade.currentHome$.pipe(
    isDefined(),
    share(),
  );

  constructor(
    private homesFacade: HomesFacade,
    private activatedRoute: ActivatedRoute,
    private pageLoaderFacade: PageLoaderFacade
  ) {}

  async ngOnInit() {
    this.pageLoaderFacade.handlePageLoader()
    const homeId = this.activatedRoute.snapshot.params.home_id;
    this.homesFacade.selectHome(homeId);
    await firstValueFrom(this.home$);
  }

}
