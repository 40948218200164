import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { first, switchMap, tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CallbackService } from '@library/utils/services/callback.service';
import { EnumPageLoaderActions, HidePageLoader, ShowPageLoader } from '@library/store/page-loader/page-loader.actions';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';

@Injectable({providedIn: 'root'})
export class PageLoaderFacade {

  isPageLoading$ = this.store$.pipe(select('isPageLoading'));

  constructor(public store$: Store<SettingsState>, public callback: CallbackService, public actions$: Actions) {}

  /**
   * Handles the page loader: hides the web page loader, then sends the onPageLoaded callback
   * @param onCompleteAction Optional: if no parameters, hides the page loader, otherwise hides the page loader when the action is fired
   */
  handlePageLoader(onCompleteAction?: string) {
    this.store$.dispatch(new ShowPageLoader());

    if (!onCompleteAction) {
      this.hidePageLoader().subscribe(() => {
        this.callback.call(this.callback.list.onPageLoaded);
      });
    } else {
      this.actions$.pipe(
        ofType(onCompleteAction),
        first(),
        switchMap(() => this.hidePageLoader()),
        tap(() => {
          this.callback.call(this.callback.list.onPageLoaded);
        }),
      ).subscribe();
    }
  }

  showPageLoader() {
    this.store$.dispatch(new ShowPageLoader());
  }

  /**
   * Hides the page loader
   * @returns Observable on action of type HidePageLoaderSuccess when it is fired
   */
  hidePageLoader(): Observable<Action> {
    this.store$.dispatch(new HidePageLoader());

    return this.actions$.pipe(
      ofType(EnumPageLoaderActions.HidePageLoaderSuccess),
      take(1),
    );
  }


}
