import { ModuleScenario, Scenario } from './scenarios.interface'

export interface ScenariosState {
  all: Scenario[];
  module: ModuleScenario;
  currentScenarioId: string;
}

export const initialScenariosState: ScenariosState = {
  all: [],
  module: null,
  currentScenarioId: null
};
