import { createReducer, on } from "@ngrx/store";
import { Enums } from "src/app/constants/App.constant";
import { CreateNewScheduleSuccess, LoadScheduleVeluxSuccess, SetCurrentScheduleCard, SetScheduleCardData, UpdateScheduleSuccess } from "./schedules.action";
import { ScheduleVelux } from "./schedules.interface";
import { initialSchedulesStateVelux } from "./schedules.state";



export const SchedulesVeluxReducers = createReducer(
    initialSchedulesStateVelux,

    on(LoadScheduleVeluxSuccess, (state, {homes}) => {

        const algos = homes.map(h => {

            if(!!h.schedules){
                const algoSchedules = h.schedules.find((schedule: any) => schedule.type === 'algo' && schedule.selected)

                if(typeof algoSchedules !== "undefined"){
                    return {...h.schedules.find((schedule: any) => schedule.type === 'algo' && schedule.selected), home_id: h.id, isNew: false};
                }else{
                    return {home_id: h.id, isNew: true};
                }
            }else{
                return {home_id: h.id, isNew: true};
            }
            
        }) as ScheduleVelux[]

        const events = homes.map(h => {

            if(!!h.schedules){
                const eventSchedules = h.schedules.find((schedule: any) => schedule.type === 'event' && schedule.selected)

                if(typeof eventSchedules !== "undefined"){
                    return {...h.schedules.find((schedule: any) => schedule.type === 'event' && schedule.selected), home_id: h.id, isNew: false};
                }else{
                    return {home_id: h.id, isNew: true};
                }
            }else{
                return {home_id: h.id, isNew: true};
            }
            
        }) as ScheduleVelux[]
 
 
        return {
            ...state,
            schedules: {
                algos,
                events
            }
        }
    }),


    on(SetScheduleCardData, (state, {data}) => {
        return {
            ...state,
            scheduleToAdd: {
                ...data
            }
        }
    }),


    on(CreateNewScheduleSuccess, (state, {data}) => {
        
        let events = state.schedules.events
        let algos = state.schedules.algos

        if(data.schedule_type === "event"){
            const currentEvent = state.schedules.events.find(a => a.home_id ===  data.home_id)
            const currentEventUpdate = {
                ...currentEvent,
                ...data,
                isNew: false
            } as ScheduleVelux
            const allEvents = state.schedules.events.filter(a => a.home_id !== data.home_id)
            events = [...allEvents, currentEventUpdate]
        }else{
            const currentAlgo = state.schedules.algos.find(a => a.home_id ===  data.home_id)
            const currentAlgoUpdate = {
                ...currentAlgo,
                ...data,
                isNew: false
            } as ScheduleVelux
            const allAlgos = state.schedules.algos.filter(a => a.home_id !== data.home_id)
            algos = [...allAlgos, currentAlgoUpdate]
        }

        return {
            ...state,
            scheduleToAdd: {
                begin_time_ref: Enums.timings.HOURLY,
                begin_time: 540,
                end_time_ref: Enums.timings.HOURLY,
                end_time: 1320
            },
            schedules: {
                events,
                algos
            }
        }


    }),

    on(UpdateScheduleSuccess, (state, {data}) => {
        let events = state.schedules.events
        let algos = state.schedules.algos

        if(data.schedule_type === "event"){
            const currentEvent = state.schedules.events.find(a => a.home_id ===  data.home_id)
            const currentEventUpdate = {
                ...currentEvent,
                ...data
            } as ScheduleVelux
            const allEvents = state.schedules.events.filter(a => a.home_id !== data.home_id)
            events = [...allEvents, currentEventUpdate]
        }else{
            const currentAlgo = state.schedules.algos.find(a => a.home_id ===  data.home_id)
            const currentAlgoUpdate = {
                ...currentAlgo,
                ...data
            } as ScheduleVelux
            const allAlgos = state.schedules.algos.filter(a => a.home_id !== data.home_id)
            algos = [...allAlgos, currentAlgoUpdate]
        }
        

        return {
            ...state,
            currentScheduleCard: null,
            schedules: {
                events,
                algos
            }
        }
    }),


    on(SetCurrentScheduleCard, (state, {card}) => {

        return {
            ...state,
            currentScheduleCard: {...card}
        }
    })


)