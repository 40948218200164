import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
    /**
     * Text to display in badge
     */
    @Input() text: string;

    @Input() circle = false;

    @Input() badgeStyleClass = '';

    @Input() color = ''


    /**
     * Size of badge. Can be small (default), medium, large or fluid (adapts to the content)
     */
    @Input() size = 'default';

    badgeContainerClasses: string;
    badgeStyleClasses: string;

    ngOnInit() {
      this.badgeContainerClasses = `is-${this.size} ${this.circle ? 'circle' : ''} is-${this.color}`;
      this.badgeStyleClasses = `badge ${this.badgeStyleClass}`;
    }
}


/**
 * Used in Settings Lux
 * Used in Settings Legrand
 */
