import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MapDirective } from "./map.directive";

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
      MapDirective,
  ],
  declarations: [MapDirective]
})
export class MapDirectiveModule { }
