<ng-container *ngIf="homeLocationVM$ | async as vm">
  <form [formGroup]="locationForm" (ngSubmit)="updateHomeLocation(vm.newCoords)" class="is-fullheight">
    <lay-panel styleClass="flex-column">
      <lay-panel-content>

        <lay-panel-block size="large" class="is-borderless has-border-bottom">
          <div class="is-block is-fullwidth">
            <app-input-label>{{ 'common-settings.__ENTER_ADDRESS' | LANG }}</app-input-label>
            <app-input-text
              formControlName="address"
              updateOn="change"
              [placeholder]="'common-settings.__ATTRIBUTE_ADDRESS' | LANG">
            </app-input-text>
          </div>
        </lay-panel-block>

        <div class="is-relative" *ngIf="autoCompletion$ | async as autoComplete">
          <div class="dropdown-wrapper">
            <lay-panel-block *ngFor="let place of autoComplete"
              styleClass="dropdown-line"
              (click)="selectPosition(interchangeCoordinates(place.coordinates))">
                <app-text [text]="place.address"></app-text>
            </lay-panel-block>
          </div>
        </div>
        <div
          id="map"
          map
          [markerIcon]="customMarker"
          [coordinates]="vm.newCoords"
          (changePosition)="positionChange($event)"
          (click)="closeDropdown()">
          <div class="map-display">
              <app-text
                *ngIf="!(submitableForm$ | async)"
                id="altitude"
                class="is-family-medium"
                [text]="'▵ ' + vm.homeAltitude">
              </app-text>
              <app-icon
                id="geolocate"
                class="is-align-end text-24"
                [name]="'tracking'">
              </app-icon>
          </div>
        </div>
      </lay-panel-content>
      <lay-panel-footer *ngIf="submitableForm$ | async">
        <lay-button class="is-flex-button">
          <button 
            type="submit" 
            class="is-primary-button"
            [ngClass]="{'is-loading': confirmLoader.loading$ | async}"
            >
            <app-text [text]="'common-app.__CONFIRM' | LANG"></app-text>
          </button>
        </lay-button>
      </lay-panel-footer>
    </lay-panel>
  </form>
</ng-container>
