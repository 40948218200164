import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { ConfigFacade } from '@library/store/config/config.facade';
import { HomesFacade, HOMES_FACADE } from '@library/store/homes/homes.facade';
import { EnumModulesActions } from '@library/store/modules/modules.action';
import { ModulesModule } from '@library/store/modules/modules.module';
import { ModuleSelectResolver } from '@library/store/modules/modules.resolver';
import { PageLoaderFacade } from '@library/store/page-loader/page-loader.facade';
import { RoomsFacade } from '@library/store/rooms/rooms.facade';
import { SchedulesService } from '@library/store/schedules/schedules.service';
import { UserFacade } from '@library/store/user/user.facade';
import { HeaderModule } from '@library/ui/layout/header/header.module';
import { MainModule } from '@library/ui/layout/main/main.module';
import { UIModule } from '@library/ui/ui.module';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { Analytics } from '@library/utils/services/analytics.service';
import { CallbackService } from '@library/utils/services/callback.service';
import { MAPBOX_ACCESS_TOKEN } from '@library/utils/services/mapbox.service';
import { NavigationCounter } from '@library/utils/services/navigation-counter.service';
import { NOTIFICATIONS } from '@library/utils/services/notifications.service';
import { UnitConverterService } from '@library/utils/services/unit-converter.service';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InitLoader, InitLoaderFactory } from './loaders/init.loader';
import { SchedulesServiceVelux } from './stores/schedules/schedules.service';
import { StateModule } from './stores/state.modules';
import { BlockBackButtonGuard } from './views/block-back-button.guard';
import { HomePageModule } from './views/home-page/home-page.module';
import { ClimateAutoSettingsService } from './views/schedule-automations-page/room-automations-page/climate-algo-settings-page/climate-auto-settings.services';
import { AirQualityService } from './views/schedule-automations-page/room-automations-page/iaq-dashboard-page/iaq-dashboard-page.service';
import { UsersPageModule } from './views/users-page/users-page.module';


const appModules = [
  AppRoutingModule,
  MainModule,
  HeaderModule,
  PipesModule,
  BrowserAnimationsModule,
  HttpClientModule,
  StateModule,
  UIModule,
  ModulesModule,
  HomePageModule,
  UsersPageModule
];



const appServices = [
  CallbackService,
  UserAgentService,
  DeviceDetectorService,
  CookieService,
  NavigationCounter,
  UnitConverterService,
  SchedulesServiceVelux,
  ClimateAutoSettingsService
];

const providers = [
  ConfigFacade,
  RoomsFacade,
  UserFacade,
  HomesFacade,
  PageLoaderFacade,
  ModuleSelectResolver,
  {
    provide: NOTIFICATIONS,
    useValue: [
      {
        message: 'common-settings.__MOVE_MODULE_SUCCESS',
        iconName: 'check',
        timeToDisplay: 8000,
        actionType: EnumModulesActions.UpdateModuleNameSuccess,
        notificatonType: 'success'
      }
    ]
  },
  {
    provide: APP_INITIALIZER,
    useFactory: InitLoaderFactory,
    multi: true,
    deps: [InitLoader]
  },
  {
    provide: MAPBOX_ACCESS_TOKEN,
    useValue: environment.mapboxAccessToken
  },
  {
    provide: HOMES_FACADE,
    useExisting: HomesFacade
  },
  {
    provide: SchedulesService,
    useClass: SchedulesService
  },
  AirQualityService,
  BlockBackButtonGuard,
  Analytics
];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...appModules
  ],
  providers: [
    ...appServices,
    ...providers
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
