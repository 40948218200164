<ng-container *ngIf="(viewModel$ | async) as vm">
  <lay-panel>

    <lay-panel-content>

      <lay-panel-block size="fluid" class="users-image"></lay-panel-block>

      <lay-panel-block class="has-gradient is-justify-center" size="fluid" styleClass="is-borderless is-paddingless-bottom">
        <app-text class="has-text-black text-17 text-align-center is-family-semiBold" [text]="'common-settings.__SHARE_HOME_TITLE' | LANG"></app-text>
      </lay-panel-block>


      <lay-panel-block size="fluid" styleClass="side-borderless is-paddingless-top">
        <lay-title>

          <app-text class="has-text-black is-family-semiBold" [text]="'settings.__FULL_ACCESS' | LANG">
          </app-text>

        </lay-title>

      </lay-panel-block>

      <lay-panel-block *ngFor="let user of vm.homeUsers" size="default" styleClass="side-borderless">

        <el-email-badge [color]="'primary-background'" [text]="user.name"></el-email-badge>

        <app-text [text]="user.name" class="user-name user-list has-text-black is-family-medium"></app-text>

        <div class="is-align-end is-flex">
          <app-text *ngIf="user.is_caller"
            class="has-margin-centered current-user-indicator has-text-primary is-family-medium"
            [text]="'(' + ('common-settings.__USER_ME' | LANG) + ')'">
          </app-text>

          <app-icon name="remove" class="is-align-end is-cursor-pointer has-text-grey-dark is-size-4"
            (click)="removeUser(user)">
          </app-icon>

        </div>

      </lay-panel-block>

      <!-- For hovering button -->
      <lay-panel-block size="large" transparent="true">
      </lay-panel-block>
    </lay-panel-content>

    <lay-panel-footer styleClass="is-floating">

      <lay-button class="is-flex-button">

        <button
          [share]
          [shareAsync]="homeInvite$"
          (hasShared)="hasCopied($event)"
          (click)="copied.next(null)"
          [ngClass]="{
            'has-shared': vm.hasCopied,
            'is-black-button': true,
            'has-text-white': true,
            'is-rounded': true,
            'is-loading': vm.hasCopied === null
          }">
          <app-icon
            *ngIf="vm.hasCopied"
            class="is-size-4"
            [name]="'check'">
          </app-icon>
          <app-text
            [text]="vm.hasCopied ? ('common-settings.__LINK_COPIED' | LANG) : ('common-settings.__ADD_USER' | LANG)">
          </app-text>
        </button>

      </lay-button>

    </lay-panel-footer>

  </lay-panel>

  <!-- Confirm modal -->
  <app-modal [display]="removeUserModalDisplay$ | async">
    <app-modal-body class="items-align-center">
      <app-modal-error-icon></app-modal-error-icon>

      <app-text
        class="text has-text-black is-spaced is-family-medium"
        [text]="'common-settings.__B2B_DISSOCIATE_OTHERS_TITLE' | LANG">
      </app-text>

      <app-text
        class="text is-size-7 has-text-centered is-family-medium"
        [text]="setConfirmText(vm.selectedUser)">
      </app-text>

      <lay-button class="is-flex-button">
        <button
          [ngClass]="{
            'is-loading': isLoading$ | async
          }"
          class="is-alert-button is-fullwidth is-fullheight has-text-white is-rounded"
          (click)="removeUserFromHome()"
          type="submit">
          <app-text
            [text]="'common-settings.__DISSOCIATE_APP' | LANG">
          </app-text>
        </button>
      </lay-button>
    </app-modal-body>

    <app-modal-footer (click)="removeUserModalDisplay$.next(false)">
      <app-text
        [text]="'common-settings.__CANCEL' | LANG"
        class="has-text-grey-dark is-cursor-pointer">
      </app-text>
    </app-modal-footer>
  </app-modal>
</ng-container>
