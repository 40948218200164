import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { GetUserSuccess } from '@library/store/user/user.action'
import { LoadLangs } from '@library/store/lang/lang.actions'
import { supportedLangs } from 'src/assets/supportedLangs';

@Injectable()
export class InitEffects {
    constructor(
        private actions$: Actions
    ) { }


    getUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetUserSuccess),
            map((action) => {

                const language: string = action.user.administrative.lang ? action.user.administrative.lang : 'en-US';
                const countryCode: string = language.substr(0, 2);


                const veluxMultiLang = supportedLangs.SupportedLanguage[language]
                    || supportedLangs.BackupLanguage.multi[countryCode]
                    || supportedLangs.DefaultLanguage.multi;

                const singleLang = supportedLangs.SupportedLanguage.single[language]
                    || supportedLangs.BackupLanguage.single[countryCode]
                    || supportedLangs.DefaultLanguage.single;



                return new LoadLangs({
                    aliases: [
                        { name: 'common-settings', locale: singleLang },
                        { name: 'common-app', locale: singleLang },
                        { name: 'settings', locale: veluxMultiLang },
                        { name: 'app', locale: veluxMultiLang },
                        { name: 'kit', locale: veluxMultiLang },
                        { name: 'extractcommon', locale: veluxMultiLang },
                    ],
                    locale: action.user.administrative.reg_locale,
                });
            })
        )
    );
}
