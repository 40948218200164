import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { Observable, retry, map, take, catchError, of } from 'rxjs';
import { HomeMeasure } from './homes.interface';

@Injectable()
export class HomesServiceVelux {
  constructor(private http: NgrxHttpClient) {
  }


  getHomeNotifications(params) {
    return this.http.get('getnotificationsettings', params);
  }


  setHomeNotifications(params) {
    return this.http.post('setnotificationsettings', params);
  }

  homeMeasures(params: {[key: string]: any}): Observable<any>{
    return this.http.post('gethomemeasure', params).pipe(
      retry(5),
      map(m => this.parseMeasuresData(m.body.home.modules[0].measures)),
      take(1),
      catchError(() => of({home: {id: params.home.id, modules: []}})),
    );
  }


  // Parse the datas returned from the getMeasure function
  private parseMeasuresData(measures: HomeMeasure[])
  {
      const values = [];

      for (let i = 0; i < measures.length ; i++)
      {
          const tab = measures[i].value;
          for (let j = 0; j < tab.length; j++)
          {
              values.push(tab[j][0]);
          }
      }
      return values;
  }
}
