import { HomeVelux } from './homes.interface';

const emptyHomes: HomeVelux[] = [];

export interface HomesStateVelux {
  homes: HomeVelux[];
  currentHomeId: string;
  loading: boolean;
  /**
   * For network error 400
   * Used to show/hide the error modal
   */
  errorModalDisplay: boolean;
}

export const initialHomesStateVelux: HomesStateVelux = {
  homes: emptyHomes,
  currentHomeId: null,
  loading: false,
  errorModalDisplay: false,
};
