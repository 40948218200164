import { Injectable } from "@angular/core";
import { SettingsState } from "@library/utils/interfaces/settings-state.interface";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, of, switchMap } from "rxjs";
import { AirQualityService } from "src/app/views/schedule-automations-page/room-automations-page/iaq-dashboard-page/iaq-dashboard-page.service";
import { GetAllHomeMeasures, GetAllHomeMeasuresFailure, GetAllHomeMeasuresSuccess, GetNotificationSettings, GetNotificationSettingsFailure, GetNotificationSettingsSuccess, SetNotificationSettings, SetNotificationSettingsSuccess } from "./homes.action";
import { HomesServiceVelux } from "./homes.service";

@Injectable()
export class HomesEffectsVelux {
  constructor(
    private homesService: HomesServiceVelux,
    private actions$: Actions,
    private store: Store<SettingsState>,
    private airQualityService: AirQualityService
  ) { }

  getNotificationSettings$ = createEffect(() => this.actions$.pipe(
    ofType(GetNotificationSettings),
    switchMap((action) => {
      return this.homesService.getHomeNotifications({ home_id: action.home_id }).pipe(
        map((notificationSettingsData: any) => GetNotificationSettingsSuccess({ notification_settings: notificationSettingsData.notification_settings })),
        catchError(({ error }) => of(GetNotificationSettingsFailure({ error })))
      )
    })
  ))

  setNotificationSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SetNotificationSettings),
    switchMap((action) => {
      let params = {settings: {}}
      params.settings[action.homeId] = {NXG: {...action.notification_settings}}

      return this.homesService.setHomeNotifications(params).pipe(
        map(() => SetNotificationSettingsSuccess({ notification_settings: action.notification_settings, homeId: action.homeId })),
        catchError(({ error }) => of(GetNotificationSettingsFailure({ error })))
      )
    })
  ))


  getAllHomeMeasures$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllHomeMeasures),
    switchMap((params) => {

      return this.airQualityService.getAllMeasures(params).pipe(
        map((measures) => {
          return GetAllHomeMeasuresSuccess({measures, home_id: params['home_id']})
        }),
        catchError(({ error }) => of(GetAllHomeMeasuresFailure({ error })))
      )
    })
  ))

}


