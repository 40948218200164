<form [formGroup]="timezoneForm" (ngSubmit)="updateTimezone()" (keydown.enter)="$event.preventDefault()" class="is-fullheight">
    <lay-panel class="flex-column">
      <lay-panel-content>
        <lay-panel-block size="large" styleClass="side-borderless" class="is-borderless has-border-bottom">
          <div class="is-block is-fullwidth">
            <app-input-label>{{ 'common-app.__COM_SETTINGS_TIMEZONE' | LANG }}</app-input-label>
            <app-input-text
              formControlName="timezone"
              updateOn="change"
              [placeholder]="'e.g. Europe, Paris'">
            </app-input-text>
          </div>
        </lay-panel-block>

        <ng-container *ngIf="results$ | async as results">
          <lay-panel-block
            size="default"
            *ngFor="let timezone of results"
            styleClass="dropdown-line side-borderless"
            (click)="selectTimezone(timezone)">
              <app-text class="title is-6" [text]="timezone"></app-text>

              <app-single-selection-icon class="is-align-end" [selected]="isSelectedTimezone(timezone)"></app-single-selection-icon>
          </lay-panel-block>

          <lay-panel-block size="small" transparent="true" *ngIf="results.length === 0">
            <app-text class="title is-6" [text]="'common-settings.__COM_SEARCH_NO_RESULTS' | LANG"></app-text>
          </lay-panel-block>

        </ng-container>
      </lay-panel-content>
      <lay-panel-footer *ngIf="selectedTimezone !== oldTimezone">
        <lay-button class="is-flex-button">
          <button type="submit" class="is-primary-button" [ngClass]="{'is-loading': confirmLoader.loading$ | async}">
            <app-text [text]="'common-app.__CONFIRM' | LANG"></app-text>
          </button>
        </lay-button>
      </lay-panel-footer>

    </lay-panel>
  </form>
