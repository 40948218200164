import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { AppReducers } from './app.reducers';
import { PageLoaderEffects } from '@library/store/page-loader/page-loader.effects'
import { NgrxHttpClient } from '@library/utils/services/http-service'
import { RoomsModule } from '@library/store/rooms/rooms.module';
import { ConfigModule } from '@library/store/config/config.module';
import { LangModule } from '@library/store/lang/lang.module';
import { UserModule } from '@library/store/user/user.module';
import { InitEffects } from '../loaders/init.effects';
import { HomesEffectsVelux } from './homes/homes.effect';
import { HomesServiceVelux } from './homes/homes.service';
import { ModulesModule } from '@library/store/modules/modules.module';
import { LoadersModule } from '../loaders/loaders.module';
import { HomesEffects } from '@library/store/homes/homes.effect';
import { HomesService } from '@library/store/homes/homes.service';
import { ScenariosModule } from '@library/store/scenarios/scenarios.module';
import { SchedulesEffectsVelux } from './schedules/schedules.effect';
import { environment } from '@environments/environment';

@NgModule({
    imports: [
        StoreModule.forRoot(AppReducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        EffectsModule.forRoot([
            PageLoaderEffects,
            InitEffects,
            HomesEffectsVelux,
            HomesEffects,
            SchedulesEffectsVelux
        ]),
        RoomsModule,
        ConfigModule,
        LangModule,
        UserModule,
        ModulesModule,
        LoadersModule,
        ScenariosModule,
        ...(environment.production ? [] : [StoreDevtoolsModule.instrument()]),
    ],
    providers: [
        NgrxHttpClient,
        HomesService,
        HomesServiceVelux
    ],
})
export class StateModule { }
