import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'el-email-badge',
  templateUrl: './email-badge.component.html',
  styleUrls: ['./email-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailBadgeComponent implements OnChanges {

  /**
   * Text to display in badge
   */
  @Input() text: string;

  /**
   * Size of badge. Can be small (default), medium, large or fluid (adapts to the content)
   */
  @Input() size = 'default';

  /**
   * Add style to badge
   */
  @Input() badgeStyleClass = '';

  @Input() color = ''

  // Generates single letter for badge
  makeBadge(text) {
    if (text) { return text.slice(0, 1).toUpperCase(); }
  }

  ngOnChanges() {
    this.text = this.makeBadge(this.text);
  }

}
