import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { scenariosReducer } from './scenarios.reducer';
import { ScenariosService } from './scenarios.service';
import { ScenariosFacade } from './scenarios.facade';
import { ScenariosEffects } from './scenarios.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('scenarios', scenariosReducer),
    EffectsModule.forFeature([
      ScenariosEffects,
    ]),
  ],
  providers: [
    ScenariosService,
    ScenariosFacade,
  ]
})
export class ScenariosModule {}
