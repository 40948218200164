import { Component, Inject, Injector, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { Loader } from '@library/utils/classes/loader';
import { BackOnAction } from '@library/utils/decorators/back-on-action.decorator';
import { EnumHomesActions } from '@library/store/homes/homes.action';
import { HomesFacade, HOMES_FACADE } from '@library/store/homes/homes.facade';
import { Home, PlaceUpdate } from '@library/store/homes/homes.interface';

@Component({
  selector: 'view-edit-user-timezone',
  templateUrl: './edit-user-timezone.component.html',
  styleUrls: ['./edit-user-timezone.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserTimezoneComponent {

  @Input() home: Home;

  /**
   * All possible timezones
   */
  private allTimezone: string[] = moment.tz.names()
    .map(tz => tz.replace(/\//g, ', ').replace(/_/g, ' '))
    .filter((tz) => {
      return !tz.includes('Etc') && tz.includes(',')
    });

  /**
   * Selected timezone
   */
  public selectedTimezone: string;

  /**
   * Old timezone that will be compared to the selected one
   * Used to handle the confirm button display
   */
  public oldTimezone: string;

  /**
   * Form from template
   * Used to get user input and change store accordingly
   */
  timezoneForm = new FormGroup({
    timezone: new FormControl('UTC', Validators.required),
  });

  /**
   * Data from store we want to compare the form to
   * Used to know if form data changes from store data
   */
  timezoneForm$ = this.facade.homeTimezone$.pipe(
    filter(timezone => !!timezone),
    map(timezone => {
      // Prettify the timezone from store: Europe/Isle_Of_Man -> Europe, Isle Of Man
      timezone.replace(/\//g, ', ').replace(/_/g, ' ');
      this.timezoneForm.patchValue(
        { timezone: '' },
        /**
         * "emitEvent: false" : doesn't emit the changeValue event so we're not triggering it a second time when patching the value
         * "onlySelf: true"   : each change only affects this control
         */
        { emitEvent: false, onlySelf: true }
      );
      this.oldTimezone = timezone;
      this.selectedTimezone = timezone;
    }),
  ).subscribe();

  /**
   * Gets the timezone property of the form
   */
  get timezone(): AbstractControl {
    return this.timezoneForm.get('timezone');
  }

  /**
   * Timezones to display after search.
   * Emits:
   *    - array of timezones according to the search,
   *    - empty array when there are no results,
   *    - null when user clicks on timezone
   */
  results$: Observable<string[]> = this.facade.homeTimezone$.pipe(
    filter((st) => !!st),
    switchMap(selectedTimezone => {
      return this.timezone.valueChanges.pipe(
        map((timezone: string) =>
          this.allTimezone.filter(tz =>
            tz.toLowerCase().replace(/(\s|,)/g, '').indexOf(timezone.toLowerCase().replace(/(\s|,)/g, '')) !== -1
          )
        ),
        startWith([selectedTimezone.replace(/\//g, ', ').replace(/_/g, ' ')].concat(this.allTimezone.filter(
          (tz) => {
            tz = tz.split(', ').join('/');
            return tz !== selectedTimezone;
          }
        ))),
      );
    })
  );

  /**
   * Loader handling the spinning of the confirm button
   */
  confirmLoader: Loader;

  constructor(
    public injector: Injector,
    public actions: Actions,
    @Inject(HOMES_FACADE) public facade: HomesFacade) {
      this.confirmLoader = new Loader(this.actions);
      this.facade.currentHome$.pipe(filter(h => !!h), take(1)).subscribe(home => {
        this.home = home;
      });
  }

  getReadableSelectedTimezone() {
    return this.selectedTimezone ? this.selectedTimezone.split('/').join(', ') : '';
  }

  isSelectedTimezone(timezone: string) {
    let formatted = timezone.split(', ').join('/');
    // Some TZ have spaces in between when formatted, need to be reverted to underscores
    formatted = formatted.split(' ').join('_');
    return formatted === this.selectedTimezone;
  }

  /**
   * Changes the selected timezone
   */
  selectTimezone(tz): void {
    // Remove all formatting when selecting
    this.selectedTimezone = tz.split(', ').join('/').split(' ').join('_');
  }

  /**
   * Updates the home timezone
   */
  @BackOnAction(EnumHomesActions.UpdateHomePlaceSuccess, EnumHomesActions.UpdateHomePlaceFailure)
  updateTimezone(): void {
    // Make the timezone understandable for the request: Europe, Isle Of Man -> Europe/Isle_Of_Man
    const tz = this.selectedTimezone.replace(/, /g, '/').replace(/ /g, '_');

    const placeUpdate: PlaceUpdate = {
        home_id: this.home.id,
        tz,
    };

    this.facade.updatePlace(placeUpdate);
    this.confirmLoader.loadUntil([EnumHomesActions.UpdateHomePlaceSuccess, EnumHomesActions.UpdateHomePlaceFailure]);
  }
}
