import { Component, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { PageLoaderFacade } from '@library/store/page-loader/page-loader.facade';
import { isDefined } from '@library/utils/helpers/store/is-defined';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { HeaderService } from '@library/utils/services/header.service';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent implements OnInit {

  home$ = this.homesFacade.currentHome$.pipe(isDefined());

  constructor(
    private pageLoaderFacade: PageLoaderFacade,
    private homesFacade: HomesFacade,
    private header: HeaderService,
    private langPipe: LangPipe,
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.pageLoaderFacade.handlePageLoader();
    this.header.push({
      title: this.langPipe.transform('common-app.__COM_SETTINGS_LOCATION')
    });
    this.titleService.setTitle('Home advanced settings / Location')
  }

}
