export class Enums {
  public static VeluxType = {
      'AWNING_BLIND': 'awning_blind',
      'BLIND': 'blind',
      'DEFAULT': 'default',
      'SHUTTER': 'shutter',
      'VENETIAN_BLIND': 'venetian_blind',
      'WINDOW': 'window',
      'NXS': 'NXS',
      'values': [
              'default',
              'window',
              'blind',
              'venetian_blind',
              'shutter',
              'awning_blind'
      ]
  };

  public static active_control_auto_restart_values = [
    { label: '30 min', value: 30 },
    { label: '1h', value: 60 },
    { label: '2h', value: 60 * 2 },
    { label: '4h', value: 4 * 60 }
  ]; // en min

  public static timings = {
    'HOURLY': 'hourly',
    'SUNRISE': 'sunrise',
    'SUNSET': 'sunset'
  }

  public static ScheduleEnums = {
    'SCHEDULE_STEP': 5,
    'MIN_HOUR': 0,
    'MAX_HOUR': 1435,
    // Used for detailing the min and max possible values for Sunrise/Sunset offsets in the schedules
    'MAX_SUN_OFFSET': 90,
    'MIN_SUN_OFFSET': -90,
    'SUN_OFFSET_STEP': 15
  }


  public static AlgoTypes = {
    CO2: 'algo_enable_co2',
    REGULAR_AIRING: 'algo_enable_air_recycling',
    NIGHT_COOLING: 'algo_enable_night_ventilation',
    HUMIDITY: 'algo_enable_humidity',
    COOLING: 'algo_enable_temperature',
    HEAT_PROTECTION: 'algo_enable_sun_screening'
  }


  public static sensorAlgos = [
    {
      value: Enums.AlgoTypes.COOLING,
      label: '__ALGO_AIRING_TEMPERATURE',
      icon: 'velux-temp',
      badge: '__ALGO_COMFORT_BADGE',
      description: '__ALGO_AIRING_TEMPERATURE_DETAILS'
    },
    {
      value: Enums.AlgoTypes.HUMIDITY,
      label: '__ALGO_AIRING_HUMIDITY',
      icon: 'velux-humidity',
      badge: '__ALGO_HEALTHY_BADGE',
      description: '__ALGO_AIRING_HUMIDITY_DETAILS'
    },
    {
      value: Enums.AlgoTypes.CO2,
      label: '__ALGO_AIRING_CO2',
      icon: 'velux-co-2',
      badge: '__ALGO_COMFORT_BADGE',
      description: '__ALGO_AIRING_CO2_DETAILS'
    },
    {
      value: Enums.AlgoTypes.NIGHT_COOLING,
      label: '__ALGO_AIRING_NIGHT',
      icon: 'velux-sundecreasing',
      badge: '__ALGO_COMFORT_BADGE',
      description: '__ALGO_AIRING_NIGHT_DETAILS'
    }
  ];

  public static windowAlgos = [
    {
      value: Enums.AlgoTypes.REGULAR_AIRING,
      label: '__ALGO_AIRING_DAILY',
      icon: 'wind',
      badge: '__ALGO_HEALTHY_BADGE',
      description: '__ALGO_AIRING_DAILY_DETAILS'
    }
  ];


  public static blindShutterAlgos = [
    {
      value: Enums.AlgoTypes.HEAT_PROTECTION,
      label: '__ALGO_HEAT_PROTECTION',
      icon: 'velux-sunscreening',
      badge: '__ALGO_COMFORT_BADGE',
      description: '__ALGO_SUN_SCREENING_DETAILS'
    }
  ];


  public static windowRecommended = [
    {
      card_name: '__AUTO_AIRING_REGULAR',
      card_enabled: true,
      algos: [Enums.AlgoTypes.REGULAR_AIRING],
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 1320,
      days: [0, 1, 2, 3, 4, 5, 6],
      icon: 'velux-window-open'
    },
    {
      card_name: '__AUTO_AIRING_BEDTIME',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 1320,
      end_time_ref: 'hourly',
      end_time: 1335,
      days: [0, 1, 2, 3, 4, 5, 6],
      target_position: 100,
      type: 'window',
      modules: [],
      icon: 'velux-window-open'
    },
    {
      card_name: '__AUTO_AIRING_MORNING',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 495,
      days: [0, 1, 2, 3, 4, 5, 6],
      target_position: 100,
      type: 'window',
      modules: [],
      icon: 'velux-window-open'
    }
  ];


  public static windowSSWRecommended = [
    {
      card_name: '__AUTO_AIRING_AIR_QUALITY',
      card_enabled: true,
      algos: [Enums.AlgoTypes.COOLING, Enums.AlgoTypes.HUMIDITY, Enums.AlgoTypes.CO2, Enums.AlgoTypes.REGULAR_AIRING],
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 1320,
      days: [0, 1, 2, 3, 4, 5, 6],
      icon: 'velux-window-open'
    },
    {
      card_name: '__AUTO_NIGHT_COOLING',
      card_enabled: true,
      algos: [Enums.AlgoTypes.NIGHT_COOLING],
      begin_time_ref: 'hourly',
      begin_time: 1320,
      end_time_ref: 'hourly',
      end_time: 600,
      days: [0, 1, 2, 3, 4, 5, 6],
      icon: 'velux-window-open'
    }
  ];


  public static blindShutterRecommended = [
    {
      card_name: '__AUTO_SHADING',
      card_enabled: true,
      algos: [Enums.AlgoTypes.HEAT_PROTECTION],
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 1320,
      days: [0, 1, 2, 3, 4, 5, 6],
      icon: 'velux-shutter'
    },
    {
      card_name: '__AUTO_ROLL_UP',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 480,
      target_position: 100,
      days: [0, 1, 2, 3, 4, 5, 6],
      modules: [],
      type: 'blindShutter',
      icon: 'velux-window-close'
    }
  ];

  public static shuttersOnlyBlindsOnlyRecommended = [
    {
      card_name: '__AUTO_ROLL_DOWN',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 1320,
      target_position: 0,
      days: [0, 1, 2, 3, 4, 5, 6],
      modules: [],
      type: 'shutter',
      icon: 'velux-shutter'
    }
  ];


  public static firstTimeWindowSSW = [
    // Weekday version
    {
      card_name: '__AUTO_AIRING_AIR_QUALITY',
      card_enabled: true,
      algos: [Enums.AlgoTypes.COOLING, Enums.AlgoTypes.HUMIDITY, Enums.AlgoTypes.CO2, Enums.AlgoTypes.REGULAR_AIRING],
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 1320,
      days: [0, 1, 2, 3, 4]
    },
    // Weekend version
    {
      card_name: '__AUTO_AIRING_AIR_QUALITY',
      card_enabled: true,
      algos: [Enums.AlgoTypes.COOLING, Enums.AlgoTypes.HUMIDITY, Enums.AlgoTypes.CO2, Enums.AlgoTypes.REGULAR_AIRING],
      begin_time_ref: 'hourly',
      begin_time: 540,
      end_time_ref: 'hourly',
      end_time: 1380,
      days: [5, 6]
    },
    {
      card_name: '__AUTO_NIGHT_COOLING',
      card_enabled: true,
      algos: [Enums.AlgoTypes.NIGHT_COOLING],
      begin_time_ref: 'hourly',
      begin_time: 1320,
      end_time_ref: 'hourly',
      end_time: 600,
      days: [0, 1, 2, 3, 4, 5, 6],
    }
  ];
  
  public static firstTimeWindow = [
    // Weekday version
    {
      card_name: '__AUTO_AIRING_REGULAR',
      card_enabled: true,
      algos: [Enums.AlgoTypes.REGULAR_AIRING],
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 1320,
      days: [0, 1, 2, 3, 4],
    },
    // Weekend version
    {
      card_name: '__AUTO_AIRING_REGULAR',
      card_enabled: true,
      algos: [Enums.AlgoTypes.REGULAR_AIRING],
      begin_time_ref: 'hourly',
      begin_time: 540,
      end_time_ref: 'hourly',
      end_time: 1380,
      days: [5, 6],
    },
  ];
  
  /**
   * Specific schedule targeting specifically Shutter Modules, and Blind Modules. Excludes Awning blinds
   */
  public static firstTimeOnlyShuttersOnlyBlinds = [
    // Weekday version
    {
      card_name: '__AUTO_ROLL_DOWN',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 1320,
      target_position: 0,
      days: [0, 1, 2, 3, 4],
      modules: [],
    },
    // Weekend version
    {
      card_name: '__AUTO_ROLL_DOWN',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 1380,
      target_position: 0,
      days: [5, 6],
      modules: []
    }
  ];
  
  public static firstTimeBlindShutters = [
    // Weekday version
    {
      card_name: '__AUTO_SHADING',
      card_enabled: true,
      algos: [Enums.AlgoTypes.HEAT_PROTECTION],
      begin_time_ref: 'hourly',
      begin_time: 480,
      end_time_ref: 'hourly',
      end_time: 1320,
      days: [0, 1, 2, 3, 4],
    },
    // Weekend version
    {
      card_name: '__AUTO_SHADING',
      card_enabled: true,
      algos: [Enums.AlgoTypes.HEAT_PROTECTION],
      begin_time_ref: 'hourly',
      begin_time: 540,
      end_time_ref: 'hourly',
      end_time: 1380,
      days: [5, 6],
    },
    // Weekday version
    {
      card_name: '__AUTO_ROLL_UP',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 480,
      target_position: 100,
      days: [0, 1, 2, 3, 4],
      modules: []
    },
    // Weekend version
    {
      card_name: '__AUTO_ROLL_UP',
      card_enabled: true,
      begin_time_ref: 'hourly',
      begin_time: 540,
      target_position: 100,
      days: [5, 6],
      modules: []
    }
  ];


  public static windowFilterCards = [
    {
      value: 'windows_open',
      label: '__AUTOMATION_SHORTCUT_OPEN_WINDOWS',
      icon:  'velux-window-open',
      reverse: 'windows_close'
    },
    {
      value: 'windows_close',
      label: '__AUTOMATION_SHORTCUT_CLOSE_WINDOWS',
      icon: 'velux-window-close',
      reverse: 'windows_open'
    }
  ];
  
  public static shutterFilterCards = [
    {
      value: 'shutters_open',
      label: '__AUTOMATION_SHORTCUT_OPEN_SHUTTERS',
      icon: 'velux-window-close',
      reverse: 'shutters_close'
    },
    {
      value: 'shutters_close',
      label: '__AUTOMATION_SHORTCUT_CLOSE_SHUTTERS',
      icon: 'velux-shutter',
      reverse: 'shutters_open'
    }
  ];
  
  public static awningBlindFilterCards = [
    {
      value: 'awnings_open',
      label: '__AUTOMATION_SHORTCUT_OPEN_AWNINGS',
      icon: 'velux-window-close',
      reverse: 'awnings_close'
    },
    {
      value: 'awnings_close',
      label: '__AUTOMATION_SHORTCUT_CLOSE_AWNINGS',
      icon: 'velux-shutter',
      reverse: 'awnings_open'
    }
  ];
  
  public static blindFilterCards = [
    {
      value: 'blinds_open',
      label: '__AUTOMATION_SHORTCUT_OPEN_BLINDS',
      icon: 'velux-window-close',
      reverse: 'blinds_close'
    },
    {
      value: 'blinds_close',
      label: '__AUTOMATION_SHORTCUT_CLOSE_BLINDS',
      icon: 'velux-shutter',
      reverse: 'blinds_open'
    }
  ];
}
