import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.state';
import { EnumPageLoaderActions, PageLoaderActions } from '@library/store/page-loader/page-loader.actions'
import { HomesState, initialHomesState } from '@library/store/homes/homes.state'
import { reduceReducers } from '@library/utils/helpers/store/reduce-reducers'
import { HomesReducers } from '@library/store/homes/homes.reducer'
import { ModulesReducers } from '@library/store/modules/modules.reducer'
import { ConfigReducers } from '@library/store/config/config.reducer'
import { UserReducers } from '@library/store/user/user.reducer'
import { LangReducers } from '@library/store/lang/lang.reducers'
import { RoomsReducers } from '@library/store/rooms/rooms.reducer'
import { HomesStateVelux, initialHomesStateVelux } from './homes/homes.state';
import { HomesActions } from '@library/store/homes/homes.action';
import { HomesVeluxReducers } from './homes/homes.reducer';
import { SchedulesVeluxReducers } from './schedules/schedules.reducer';

export function isPageLoading(state = true, action: PageLoaderActions) {
    switch (action.type) {
        case EnumPageLoaderActions.ShowPageLoader: return true;
        case EnumPageLoaderActions.HidePageLoader: return false;
        default: return state;
    }
}

export function HomesReducersReduced(state = initialHomesStateVelux, action: HomesActions): HomesStateVelux {
    const reducer = reduceReducers(initialHomesStateVelux, HomesReducers, HomesVeluxReducers);
    return reducer(state, action);
}


export const AppReducers: ActionReducerMap<AppState, any> = {
    config: ConfigReducers,
    user: UserReducers,
    langs: LangReducers,
    rooms: RoomsReducers,
    homes: HomesReducersReduced,
    modules: ModulesReducers,
    schedules: SchedulesVeluxReducers,
    isPageLoading,
};
