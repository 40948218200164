import { createReducer, on } from "@ngrx/store";
import { GetAllHomeMeasuresSuccess, GetNotificationSettingsSuccess, SetNotificationSettingsSuccess } from "./homes.action";
import { initialHomesStateVelux } from "./homes.state";



export const HomesVeluxReducers = createReducer(
    initialHomesStateVelux,

    on(GetNotificationSettingsSuccess, (state, { notification_settings }) => {
        const homeIds = Object.keys(notification_settings)
        let updateHomes = state.homes
        for (let id of homeIds) {
            const notification = notification_settings[id]
            updateHomes = state.homes.map(home => {
                if (home.id === id) {
                    home.notification_settings = { ...notification['NXG'] }
                }
                return home
            })
        }

        return {
            ...state,
            homes: updateHomes
        };
    }),


    on(SetNotificationSettingsSuccess, (state, { notification_settings, homeId }) => {

        const updateHomes = state.homes.map(home => {
            if (home.id === homeId) {
                home.notification_settings = { ...notification_settings }
            }
            return home
        })
        return {
            ...state,
            homes: updateHomes
        };
    }),

    on(GetAllHomeMeasuresSuccess, (state, { measures, home_id }) => {

        const updateHomes = state.homes.map(home => {
            if (home.id === home_id) {
                home.measures = { ...measures }
            }
            return home
        })
        return {
            ...state,
            homes: updateHomes
        };
    })
)