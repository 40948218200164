import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';

@Injectable({ providedIn: 'root' })
export class ScenariosService {

  constructor(
    private http: NgrxHttpClient,
  ) {}

  loadScenarios(homeId: string, deviceTypes?: Array<string>, filter?: Array<string>) {
    const params: {home_id: string, device_types?: Array<string>, filter?: Array<string>} = {
      home_id: homeId,
    }
    if (deviceTypes) {
      params.device_types = deviceTypes
    }
    if(filter){
      params.filter = filter
    }
    return this.http.getSync('getscenarios', params);
  }


  setScenarios(params) {
    return this.http.postSync('setscenarios', params);
  }

}
