import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Store } from '@ngrx/store';
import { SelectModuleAction } from './modules.action';

/**
 * Pre-fetches data before accessing the route if data has not been loaded
 *
 */
@Injectable({
    providedIn: 'root'
})
export class ModuleSelectResolver implements Resolve<void> {

 constructor(private store: Store<SettingsState>) { }

 resolve(route: ActivatedRouteSnapshot): void {

    return this.store.dispatch(new SelectModuleAction(route.params.module_id));
 }

}
