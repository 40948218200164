import { createReducer, on } from '@ngrx/store'

import { GetScenariosSuccess, SetScenariosSuccess } from './scenarios.actions'
import { initialScenariosState } from './scenarios.state'


export const scenariosReducer = createReducer(
  initialScenariosState,
  on(GetScenariosSuccess, (state, { home }) => {

    // Add modules included in scenario
    const scenarios = home.scenarios?.map(scenario => {
      // Example of module object :
      // {id: 1, scenarios: [{id: 1}, {id: 2, on: true}]}
      // If module is inclued in scenario object, scenario contains id and action property
      const modulesId: string[] = home.modules?.filter(module => {
        // Check if module is included in current scenario
        const associatedScenario = module?.scenarios?.find(moduleScenario => moduleScenario.id === scenario.id)
        if (associatedScenario && Object.keys(associatedScenario).some(key => key !== 'id')) {
          return true;
        }
        return false;
      }).map(module => module.id) // Get only module id

      return {
        ...scenario,
        modulesId
      }
    })

    return {
      ...state,
      all: scenarios || [],
      module: home.modules ? home.modules[0] : null // Use by velux to get the first module only (one module received by api)
    }
  }),


  on(SetScenariosSuccess, (state, { home }) => {
    // set scenarios Use only by velux
    const newScenario = home.modules[0].scenarios[0]
    let module = state.module

    const scenarios = state.module.scenarios.map(scenario => {
      let updateScenario = scenario
      if(updateScenario.id === newScenario.id){
        updateScenario = {...newScenario}
      }
      return updateScenario
    })

    module.scenarios = scenarios

    return {
      ...state,
      module
    }
  }),

);

