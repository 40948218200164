import { Injectable } from '@angular/core';
import { InitFacade } from './init.facade';
import { ofType, Actions } from '@ngrx/effects';
import { EnumLangActions, LoadLangsSuccess } from '@library/store/lang/lang.actions';
import { take } from 'rxjs/operators';

@Injectable()
export class InitLoader {

  constructor(
    private actions$: Actions,
    private initFacade: InitFacade,
  ) { }

  load() {
    this.initFacade.start();
    return this.actions$.pipe(
      ofType<LoadLangsSuccess>(EnumLangActions.LoadLangsSuccess),
      take(1)
    ).toPromise(); // Without .toPromise, the loader is not working
  }

}

export function InitLoaderFactory(service: InitLoader) {
  const url = window.location.href
  if (url.indexOf("#") !== -1) {
    history.replaceState({}, '', transformUrl(url))
  }
  return () => service.load();
}

export function transformUrl(url: string) {
  const urlObj = new URL(url);

  const hashSegments = urlObj.hash.split('#/')
  urlObj.hash = '';
  urlObj.pathname = hashSegments.join('/');  

  return urlObj.toString();
}