import { createAction, props } from "@ngrx/store";
import { NotificationSettings } from "./homes.interface";


export const GetNotificationSettings = createAction(
    '[Homes] get notification settings',
    props<{home_id: string}>()
)

export const GetNotificationSettingsSuccess = createAction(
    '[Homes] get notification settings success',
    props<{notification_settings: any}>()
)


export const GetNotificationSettingsFailure = createAction(
    '[Homes] get notification settings failure',
    props<{error: string}>()
)



export const SetNotificationSettings = createAction(
    '[Homes] set notification settings',
    props<{notification_settings: NotificationSettings, homeId: string}>()
)

export const SetNotificationSettingsSuccess = createAction(
    '[Homes] set notification settings success',
    props<{notification_settings: NotificationSettings, homeId: string}>()
)


export const SetNotificationSettingsFailure = createAction(
    '[Homes] set notification settings failure',
    props<{error: string}>()
)


export const GetAllHomeMeasures = createAction(
    '[HomeMeasures] get all home measures',
    props<{gateway: string, module_id: string, measureType: string, home_id: string}>()
)

export const GetAllHomeMeasuresSuccess = createAction(
    '[HomeMeasures] get all home measures success',
    props<{measures: number[][], home_id: string}>()
)


export const GetAllHomeMeasuresFailure = createAction(
    '[HomeMeasures] get all home measures failure',
    props<{error: string}>()
)