import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModulesReducers } from './modules.reducer';
import { ModulesEffects } from './modules.effect';
import { ModulesFacade } from './modules.facade';
import { ModulesService } from './modules.service';

@NgModule({
  imports: [
    StoreModule.forFeature('modules', ModulesReducers),
    EffectsModule.forFeature([
      ModulesEffects,
    ]),
  ],
  providers: [
    ModulesService,
    ModulesFacade
  ]
})
export class ModulesModule {}
