<ng-container *ngIf="(home$ | async) as home">

    <lay-panel styleClass="flex-column">
  
      <lay-panel-content>
  
  
        <lay-panel-block
          size="fluid"
          styleClass="is-cursor-pointer">
          <lay-link [route]="'location'">
            <app-text [text]="'common-settings.__LOCALISATION' | LANG" class="title"></app-text>
          </lay-link>
        </lay-panel-block>
  
        <lay-panel-block
          styleClass="is-cursor-pointer"
          size="fluid">
          <lay-link [route]="'timezone'">
            <el-heading [text]="'common-app.__COM_SETTINGS_TIMEZONE' | LANG" [subtext]="getHomeTimezonePretty(home)" [subTextClass]="'is-family-medium'"></el-heading>
          </lay-link>
        </lay-panel-block>

  
      </lay-panel-content>
  
    </lay-panel>
  
  </ng-container>