import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { GetScenarios, GetScenariosSuccess, SetScenarios, SetScenariosSuccess } from './scenarios.actions'
import * as ScenariosSelector from './scenarios.selectors';

@Injectable({providedIn: 'root'})
export class ScenariosFacade {

  constructor(
    private store$: Store,
    protected actions$: Actions
  ) { }

  scenarios$ = this.store$.pipe(select(ScenariosSelector.getScenarios));
  moduleScenarios$ = this.store$.pipe(select(ScenariosSelector.getModuleScenarios));
  defaultScenarios$ = this.store$.pipe(select(ScenariosSelector.defaultScenarios));
  customScenarios$ = this.store$.pipe(select(ScenariosSelector.customScenarios));

  loadScenarios(homeId: string, deviceTypes?: string[], filter?: string[]) {
    this.store$.dispatch(GetScenarios({homeId, deviceTypes, filter}))

    return this.actions$.pipe(
      ofType(GetScenariosSuccess),
      take(1)
    ); 
  }


  setScenarios(params) {
    this.store$.dispatch(SetScenarios({params}))

    return this.actions$.pipe(
      ofType(SetScenariosSuccess),
      take(1)
  );
  }

}
