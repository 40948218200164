import { Component, Input } from "@angular/core";


@Component({
    selector: 'app-modal-error-icon',
    templateUrl: './modal-error-icon.component.html',
})
export class ModalErrorIconComponent {
    
    @Input() name: string = 'error-circle'

}