import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserLocationComponent } from './edit-user-location.component';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../directives/directives.module';
import { MapDirectiveModule } from '@library/ui/directives/map/map.module';
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { LayoutModule } from '@library/ui/layout/layout.module'
import { MoleculesModule } from '@library/ui/molecules/molecules.module'

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    MapDirectiveModule,
    AtomsModule,
    LayoutModule,
    MoleculesModule,
  ],
  declarations: [
    EditUserLocationComponent,
  ],
  exports: [
    EditUserLocationComponent,
  ]
})
export class EditUserLocationModule { }
