import { Injectable } from '@angular/core';
import { Home } from '@library/store/homes/homes.interface';
import * as moment from 'moment-timezone';
import { Enums } from 'src/app/constants/App.constant';
import { Zone, ModulesAction, ScheduleAction, ScheduleCard, TwilightTimetable, BasicSchedule } from './schedules.interface';
import { getSunrise, getSunset } from 'sunrise-sunset-js';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { Room } from '@library/store/rooms/rooms.interface';
import { Module } from '@library/store/modules/modules.interface';
import * as isEqual from 'fast-deep-equal';

@Injectable()
export class SchedulesServiceVelux {
    constructor(
        private langPipe: LangPipe
    ) {
    }

    autoCloseValue: number

    setAutoCloseValue(value: number){
        this.autoCloseValue = value
    }

    getAutoCloseValue(){
        return this.autoCloseValue;
    }


    async getEventCardName(modules: ModulesAction[], modulesByType: any) {
        let name = '';

        const selectedModules = [].concat(...Object.values(modules));
        const windowsName = this.getEventWindowsName(selectedModules, modulesByType)
        const blindsName = this.getEventBlindsName(selectedModules, modulesByType)
        const awningBlindsName = this.getEventAwningBlindsName(selectedModules, modulesByType)
        const shuttersName = this.getEventShuttersName(selectedModules, modulesByType)

        if(windowsName.length > 0){
            name = name.length > 0 ? name + ', ' + windowsName : windowsName;
        }

        if(blindsName.length > 0){
            name =  name.length > 0 ? name + ', ' + blindsName : blindsName;
        }

        if(awningBlindsName.length > 0){
            name = name.length > 0 ? name + ', ' + awningBlindsName : awningBlindsName;
        }

        if(shuttersName.length > 0){
            name = name.length > 0 ? name + ', ' + shuttersName : shuttersName;
        }

        return name;
    }

    private getEventWindowsName(selectedModules: any[], modulesByType: any) {
        let name = ''
        // Then, map out selected module values to have array of specific module values
        const windowValues = selectedModules.filter(
            (selectedModule) => modulesByType.windows.map((window) => window.id).includes(selectedModule.id)
        ).map((selectedModule) => selectedModule.target_position);

        if (windowValues.length > 0) {
            const sameValues = windowValues.every((val, i, array) => val === array[0]);
            // If all values are the same, and are not 0 or 100 (which were already covered above)
            if (sameValues) {
                // Move if statement down a level to allow ensure else works correctly
                if (windowValues[0] !== 0 && windowValues[0] !== 100) {
                    const value = this.langPipe.transform('settings.__AUTO_MOVE_WINDOWS', [windowValues[0]]);
                    name = name.length > 0 ? name + ', ' + value : value;
                } else if (windowValues[0] === 0) {
                    const value = this.langPipe.transform('settings.__AUTO_CLOSE_ALL_WINDOWS');
                    name = name.length > 0 ? name + ', ' + value : value;
                } else {
                    const value = this.langPipe.transform('settings.__AUTO_OPEN_WINDOWS');
                    name = name.length > 0 ? name + ', ' + value : value;
                }
            } else {
                // Else, values are different and we use this name
                const value = this.langPipe.transform('settings.__AUTO_WINDOWS');
                name = name.length > 0 ? name + ', ' + value : value;
            }
        }

        return name;
    }


    private getEventBlindsName(selectedModules: any[], modulesByType: any) {

        let name = ''

        // Then, map out selected module values to have array of specific module values
        const blindValues = selectedModules.filter(
            (selectedModule) => modulesByType.blinds.map((blind) => blind.id).includes(selectedModule.id)
        ).map((selectedModule) => selectedModule.target_position);

        if (blindValues.length > 0) {
            const sameValues = blindValues.every((val, i, array) => val === array[0]);
            // If all values are the same, and are not 0 or 100 (which were already covered above)
            if (sameValues) {
                // Move if statement down a level to allow ensure else works correctly
                if (blindValues[0] !== 0 && blindValues[0] !== 100) {
                    const value = this.langPipe.transform('settings.__AUTO_MOVE_BLINDS', [(100 - blindValues[0]).toString()]);
                    name = name.length > 0 ? name + ', ' + value : value;
                } else if (blindValues[0] === 0) {
                    const value = this.langPipe.transform('settings.__AUTO_CLOSE_BLINDS');
                    name = name.length > 0 ? name + ', ' + value : value;
                } else {
                    const value = this.langPipe.transform('settings.__AUTO_OPEN_BLINDS');
                    name = name.length > 0 ? name + ', ' + value : value;
                }
            } else {
                // Else, values are different and we use this name
                const value = this.langPipe.transform('settings.__AUTO_BLINDS');
                name = name.length > 0 ? name + ', ' + value : value;
            }
        }

        return name;
    }

    private getEventAwningBlindsName(selectedModules: any[], modulesByType: any) {
        let name = ''
        // Then, map out selected module values to have array of specific module values
        const awningBlindValues = selectedModules.filter(
            (selectedModule) => modulesByType.awningBlinds.map((awningBlind) => awningBlind.id).includes(selectedModule.id)
        ).map((selectedModule) => selectedModule.target_position);

        if (awningBlindValues.length > 0) {
            const sameValues = awningBlindValues.every((val, i, array) => val === array[0]);
            // If all values are the same, and are not 0 or 100 (which were already covered above)
            if (sameValues) {
                // Move if statement down a level to allow ensure else works correctly
                if (awningBlindValues[0] !== 0 && awningBlindValues[0] !== 100) {
                    const value = this.langPipe.transform('settings.__AUTO_MOVE_AWNINGS', [(100 - awningBlindValues[0]).toString()]);
                    name = name.length > 0 ? name + ', ' + value : value;
                } else if (awningBlindValues[0] === 0) {
                    const value = this.langPipe.transform('settings.__AUTO_CLOSE_AWNINGS');
                    name = name.length > 0 ? name + ', ' + value : value;
                } else {
                    const value = this.langPipe.transform('settings.__AUTO_OPEN_AWNINGS');
                    name = name.length > 0 ? name + ', ' + value : value;
                }
            } else {
                // Else, values are different and we use this name
                const value = this.langPipe.transform('settings.__AUTO_AWNINGS');
                name = name.length > 0 ? name + ', ' + value : value;
            }
        }

        return name;
    }

    private getEventShuttersName(selectedModules: any[], modulesByType: any) {
        let name = ''

        // Then, map out selected module values to have array of specific module values
        const shutterValues = selectedModules.filter(
            (selectedModule) => modulesByType.shutters.map((shutter) => shutter.id).includes(selectedModule.id)
        ).map((selectedModule) => selectedModule.target_position);

        if (shutterValues.length > 0) {
            const sameValues = shutterValues.every((val, i, array) => val === array[0]);
            // If all values are the same, and are not 0 or 100 (which were already covered above)
            if (sameValues) {
                // Move if statement down a level to allow ensure else works correctly
                if (shutterValues[0] !== 0 && shutterValues[0] !== 100) {
                    const value = this.langPipe.transform('settings.__AUTO_MOVE_SHUTTERS', [(100 - shutterValues[0]).toString()]);
                    name = name.length > 0 ? name + ', ' + value : value;
                } else if (shutterValues[0] === 0) {
                    const value = this.langPipe.transform('settings.__AUTO_CLOSE_SHUTTERS');
                    name = name.length > 0 ? name + ', ' + value : value;
                } else {
                    const value = this.langPipe.transform('settings.__AUTO_OPEN_SHUTTERS');
                    name = name.length > 0 ? name + ', ' + value : value;
                }
            } else {
                // Else, values are different and we use this name
                const value = this.langPipe.transform('settings.__AUTO_SHUTTERS');
                name = name.length > 0 ? name + ', ' + value : value;
            }
        }
        return name;
    }

    /** Get the days Sunrise or Sunset in offset form */
    getSunriseSunsetTime(card: ScheduleCard, timeRef: string, flag: string, home: Home): number {
        /** First get time to calculate. This will make this work when Sunrise/Sunset offsets are ready */
        let time = flag === 'begin' ? card.begin_time : card.end_time;
        let offset;

        /** Need to use correct calculation based on timeRef */
        if (timeRef === Enums.timings.SUNRISE) {
            const sunriseDate = getSunrise(home.place.coordinates[1], home.place.coordinates[0]);
            /** Calculate schedule offset from hours */
            offset = sunriseDate.getHours() * 60;
            /** Add minutes to the schedule offset */
            offset = offset + sunriseDate.getMinutes();
            /** If the card included an offset, add it to the timing */
            if (time) {
                offset = offset + time;
            }
        } else {
            const sunsetDate = getSunset(home.place.coordinates[1], home.place.coordinates[0]);
            /** Calculate schedule offset from hours */
            offset = sunsetDate.getHours() * 60;
            /** Add minutes to the schedule offset */
            offset = offset + sunsetDate.getMinutes();
            /** If the card included an offset, add it to the timing */
            if (time) {
                offset = offset + time;
            }
        }
        /** If the value overflows 24h bounds, loop back around */
        if (offset > (24 * 60)) {
            offset = offset % (24 / 60);
        }
        return offset;
    }


    getAlgoCardName(algos: string[]) {
        if (algos.length > 1) {
            return this.langPipe.transform('settings.__AUTO_AIRING_AIR_QUALITY');
        } else {
            let key;
            switch (algos[0]) {
                case Enums.AlgoTypes.CO2:
                    key = '__AUTO_AIRING_CO2';
                    break;
                case Enums.AlgoTypes.COOLING:
                    key = '__AUTO_AIRING_COOL';
                    break;
                case Enums.AlgoTypes.HUMIDITY:
                    key = '__AUTO_AIRING_HUMIDITY';
                    break;
                case Enums.AlgoTypes.NIGHT_COOLING:
                    key = '__AUTO_NIGHT_COOLING';
                    break;
                case Enums.AlgoTypes.HEAT_PROTECTION:
                    key = '__AUTO_SHADING';
                    break;
                case Enums.AlgoTypes.REGULAR_AIRING:
                default:
                    key = '__AUTO_AIRING_REGULAR';
                    break;
            }
            return this.langPipe.transform('settings.' + key);
        }
    }


    getTime(card: ScheduleCard, home: Home) {
        return this.getTimeString(card, home.place.coordinates);
    }

    getDays(days: number[]): string {
        return this.convertDaysToString(days);
    }

    getEditUrl(card: ScheduleCard): string {
        return card.algos ? `edit-climate/${card.card_id}` : `edit-module/${card.card_id}`;
    }


    checkForAlgoConflicts(newCard: ScheduleCard, algoCards: ScheduleCard[]): boolean {
        for (let i = 0; i < algoCards.length; i++) {
            const concat = [...algoCards[i].algos, ...newCard.algos];
            const set = [...new Set(concat)];
            /** If they don't equal the same amount, it means there was a duplicate key */
            if (
                concat.length !== set.length &&
                (algoCards[i].begin_time === newCard.begin_time || algoCards[i].end_time === newCard.end_time)
            ) {
                return true;
            }
        }
        return false;
    }


    // Function to return string values for days of week
    public convertDaysToString(days: number[]): string {
        // All days selected
        if (days.length === 7) {
            return this.langPipe.transform('settings.__EVERYDAY');
        }

        // Only weekend sunday (index 0) and saturday (index 6)
        if (JSON.stringify(days.sort()) === '[5,6]') {
            return this.langPipe.transform('settings.__WEEKENDS');
        }

        if (JSON.stringify(days.sort()) === '[0,1,2,3,4]') {
            return this.langPipe.transform('settings.__WEEKDAYS');
        }

        const weekDays = [];
        for (const i in days) {
            if (typeof days[i] !== 'undefined') {
                let day = days[i] + 1;
                if (day === 7) {
                    day = 0;
                }
                weekDays.push(moment.weekdaysShort()[day]);
            }
        }
        return weekDays.join(' ');
    }

    public getTimeString(card: ScheduleCard, homeCoordinates?: number[]) {
        let timeString = [this.convertOffsetToTime(card.begin_time, card.begin_time_ref, homeCoordinates)];
        if (typeof card.end_time !== 'undefined' && card.end_time !== null) {
            timeString.push(this.convertOffsetToTime(card.end_time, card.end_time_ref, homeCoordinates))

            // Certain modules cards will set an end_time. Need to add this string to let the user know what happens at end
            if (card.modules && card.modules.some(mod => mod.isWindow)) {
                timeString = [...timeString, this.langPipe.transform('settings.__AUTO_CLOSE_WINDOWS')];
            }
        }
        return timeString;
    }


    // Function converst given offset to human readable time
    public convertOffsetToTime(offset: number, timing: string, coordinates: number[]): string {
        if (!timing || timing === Enums.timings.HOURLY) {
            const date = new Date(0, 0);
            // Since offset is minutes from midnight, get hours by dividing offset / 60
            date.setHours(offset / 60);
            // Get minutes by taking the remainder from offset % 60
            date.setMinutes(offset % 60);
            // Slice excess string off of returned value to only return in format 'hh:mm'
            return date.toTimeString().slice(0, 5);
        } else if (timing === Enums.timings.SUNRISE) {
            offset = !!offset ? offset : 0;
            const tempTime = getSunrise(coordinates[1], coordinates[0]);
            const time = new Date(tempTime.getTime() + ((offset) * 60000)).toLocaleTimeString('fr-FR');
            const newTime = time.substr(0, time.lastIndexOf(':'));
            const negative = Math.sign(offset) === -1;
            const off = offset ? `${negative ? '' : '+'}${offset}` : '';
            return `${this.langPipe.transform('settings.__SUNRISE')}${off} (${newTime})`;
        } else {
            offset = !!offset ? offset : 0;
            const tempTime = getSunset(coordinates[1], coordinates[0]);
            const time = new Date(tempTime.getTime() + ((offset) * 60000)).toLocaleTimeString('fr-FR');
            const newTime = time.substr(0, time.lastIndexOf(':'));
            const negative = Math.sign(offset) === -1;
            const off = offset ? `${negative ? '' : '+'}${offset}` : '';
            return `${this.langPipe.transform('settings.__SUNSET')}${off} (${newTime})`;
        }
    }


    getIcon(card: ScheduleCard): string {
        if (card.algos) {
            return 'updating';
        } else {
            return 'duration';
        }
    }

    getRoomAlgos(hasWindow: boolean, hasSensor: boolean, hasShutterBlind: boolean, hasAwningBlinds: boolean) {
        // If user has sensors, include these algos;
        let available = [];
        let unavailable = [];

        // If user has a window, include these algos
        if (hasWindow) {
            // Velux demands certain order to algos. Ineserted between some existing sensor algos
            if (hasSensor) {
                available = [...Enums.sensorAlgos];
                available.splice(3, 0, ...Enums.windowAlgos);
            } else {
                available = [...Enums.windowAlgos];
                unavailable = [...Enums.sensorAlgos];
            }

        } else {
            unavailable = [...Enums.sensorAlgos];
            unavailable.splice(3, 0, ...Enums.windowAlgos);
        }

        if (hasShutterBlind || hasAwningBlinds) {
            available = [...available, ...Enums.blindShutterAlgos];
        } else {
            unavailable = [...unavailable, ...Enums.blindShutterAlgos];
        }
        return { available, unavailable };
    }



    /**
     * Some schedules can exist at the same time. This function will determine if two cards can exist at the same time
     * returns true if cards can exist together, false if they can't
     * @param card
     * @param newCard
     */
    canExistTogether(card: ScheduleCard, newCard: ScheduleCard): boolean {
        /**
         * First, check if both cards are in the same room
         * Next, check if one card is a home card
         * Otherwise, these cards do not conflict
         */
        if (card.scenario || newCard.scenario) {
            return false;
        } else if (newCard.room_id.toString() === card.room_id.toString()) {
            /**
             * Check if both are algo cards
             * Also, check if both are module event cards
             * Otherwise, one is a Module events schedule and other is algos. Cannoy exist together
             */
            if (card.algos && newCard.algos) {
                /**
                 * Validate to see if algos are compatible
                 * Otherwise, cannot exist together
                 */
                // Merge algos together in one string for quicker evaluation
                const algos = [...card.algos, ...newCard.algos].join('');
                // All algos are compatible with one another except for nightcooling and heatprotection
                // If nightcooling is present in both cards, or heatprotection is present in both cards, it's okay
                // If nightcooling or heatprotection is only present in one card, we have a conflict
                // The regex below retrieves instances of either word from the string
                const regex = /\W*algo_enable_night_ventilation\W*/g;
                // Retrieve all instances of word from string
                // Match will return null if no matches, so instead return empty array
                const test = algos.match(regex) || [];
                // If array is empty, or has two entries, AND the two entries are the same, no conflict
                // Otherwise, we have a conflict
                const algotest = (test.length % 2 === 0 && test[0] === test[1]) ? true : false;

                if (!algotest) {
                    return algotest;
                }

                /**
                 * We also need to prevent users from creating two algo schedules,
                 * with the same algo, that start or end at the same time
                 */
                // Regex will check algos for duplicate keys
                const secondAlgos = [...card.algos, ...newCard.algos].join(' ');
                const secondRegex = /\b(\w+)\b.*\b\1\b/;
                const secondTest = secondAlgos.match(secondRegex) || [];

                /**
                 * If secondTest found duplicates, and card and newCard have matching start or end times
                 * return false
                 * Otherwise
                 * return true;
                 */
                if (
                    secondTest.length > 0 &&
                    (card.begin_time === newCard.begin_time || card.end_time === newCard.end_time)
                ) {
                    return false;
                } else {
                    return true;
                }

            } else if (card.modules && newCard.modules) {
                // First, create "map" of all modules present on card
                // Modules can only appear once per card, so if we find the same module referenced on other card, we know there is an issue
                const modulesMap = {};
                card.modules.forEach((action: ModulesAction) => {
                    // Set initial value to 0. Module can only be referenced once per card
                    if (!modulesMap[0]) {
                        modulesMap[0] = [];
                    }
                    modulesMap[0] = [...modulesMap[0], action.id];
                });
                for (const action of newCard.modules) {
                    if (modulesMap[0].includes(action.id)) {
                        // If above is true, there is a conflict
                        return false;
                    }
                }
                return true;
            } else {
                // Checking specifically for heat protection.
                // If modules don't include blinds, but algos contains on heat protection, they can exist together
                // Conversely, if all modules are blinds, and algos don't contain heat protection, they can exist together
                if (card.algos && newCard.modules || card.modules && newCard.algos) {
                    const algoCard = card.algos ? card : newCard;
                    const modulesCard = card.modules ? card : newCard;
                    const blindsShutters = modulesCard.modules.filter((module) => module.isBlindShutter);
                    const windows = modulesCard.modules.filter((module) => module.isWindow);
                    if (blindsShutters.length === 0 && algoCard.algos.includes(Enums.AlgoTypes.HEAT_PROTECTION)) {
                        return true;
                    } else if (
                        (blindsShutters.length >= 1 && windows.length === 0) &&
                        !algoCard.algos.includes(Enums.AlgoTypes.HEAT_PROTECTION)
                    ) {
                        return true;
                    }
                }
                return false;
            }
        } else {
            return true;
        }
    }



    checkSunriseSunsetConflicts(card: ScheduleCard, newCard: ScheduleCard): boolean {
        /**
         * First iterate over days of newCard
         * Note: we are not worrying about Overnight cards here and changing days, Embedded is handling this problem
         * We simply take the day listed as the day presented
         */
        for (const day of newCard.days) {
            /**
             *  If filtered card and newCard share same day, check timings
             *  Else, no conflicts
             */
            if (card.days.includes(day)) {
                /**
                 * Cases to handle:
                 * Begin Sunrise, Begin Sunrise
                 * End Sunrise End Sunrise
                 * Begin Sunset, Begin Sunset
                 * End Sunset, End Sunset
                 * Begin Hourly, Begin Hourly, times are same
                 * End Hourly, End Hourly, times are same
                 */

                /**
                 * Pull timing refs for cards for ease of use
                 */
                const newCardBeginRef = newCard.begin_time_ref || Enums.timings.HOURLY;
                const cardBeginRef = card.begin_time_ref || Enums.timings.HOURLY;
                const newCardEndRef = newCard.end_time_ref || Enums.timings.HOURLY;
                const cardEndRef = card.end_time_ref || Enums.timings.HOURLY;

                /** Covering cases where begin refs are the same  */
                if (newCardBeginRef === cardBeginRef) {

                    /**
                     * Since we know both refs are the same, we only need to check if their begin times are the same
                     * Note: time check should be at lower level to not accidentally trigger else statement
                     */
                    if (newCard.begin_time === card.begin_time) {
                        return !this.canExistTogether(card, newCard);
                    }

                    /** Also check endrefs for equality */
                } else if (newCardEndRef === cardEndRef) {

                    /**
                     * Since we know both refs are the same, we only need to check if their end times are the same
                     * Otherwise, refs are either both sunrise or sunset, and should check if can exist together
                     * Note: time check should be at lower level to not accidentally trigger else statement
                     */
                    if (
                        (newCard.end_time === card.end_time && typeof newCard.end_time !== 'undefined')
                    ) {
                        return !this.canExistTogether(card, newCard);
                    }

                }
            }
        }

        return false;
    }

    // Visual of Conflicts can be found here: https://gitlab.corp.netatmo.com/web/settingsvelux/snippets/506
    private checkSameDayConflicts(card: ScheduleCard, newCard: ScheduleCard): boolean {
        // Because we know both cards are not running overnight, we can simply compare days and times
        for (const day of newCard.days) {
            /**
             *  If filtered card and newCard share same day, check timings
             *  Else, no conflicts
             */
            if (card.days.includes(day)) {
                /**
                 * If cards share same start or end time, check if cards can coexist
                 * Also make sure at least one card is not undefined
                 * Else if card ends during duratation of other card
                 * Else if card begins during duration of other card
                 */
                if (
                    card.begin_time === newCard.begin_time ||
                    (card.end_time === newCard.end_time && typeof newCard.end_time !== 'undefined')
                ) {
                    return true;
                } else if (
                    (card.end_time < newCard.end_time && card.end_time > newCard.begin_time) ||
                    (newCard.end_time < card.end_time && newCard.end_time > card.begin_time)
                ) {
                    return true;
                } else if (
                    (card.begin_time > newCard.begin_time && card.begin_time < newCard.end_time) ||
                    (newCard.begin_time > card.begin_time && newCard.begin_time < card.end_time)
                ) {
                    return true;
                }

            }
        }

        return false;
    }


    // Visual of conflicts can be found here: https://gitlab.corp.netatmo.com/web/settingsvelux/snippets/507
    private checkDoubleOvernightConflicts(card: ScheduleCard, newCard: ScheduleCard): boolean {
        for (const day of newCard.days) {
            /**
             *  If filtered card and newCard share same day, check timings
             *  Else if filtercard includes day + 1, check timings
             *  NOTE: If day is 6, Day + 1 should be 0 to loop to beginning of week
             *  Else if filtercard includes day - 1, check timings
             *  NOTE: If day is 0, Day - 1 should be 6 to loop to beginning of week
             *  Else, no conflicts
             */
            const dayPlusOne = day === 6 ? 0 : day + 1;
            const dayMinusOne = day === 0 ? 6 : day - 1;
            if (card.days.includes(day)) {
                /**
                 * If cards share same start or end time, check if cards can coexist
                 * Else if card ends during durtation of other card
                 * Else if card begins during duration of other card
                 * Else, no conflicts
                 */
                if (newCard.begin_time === card.begin_time || card.end_time === newCard.end_time) {
                    return true;
                } else if (
                    (card.end_time < newCard.end_time) ||
                    (newCard.end_time < card.end_time)
                ) {
                    return true;
                } else if (
                    (card.begin_time > newCard.begin_time) ||
                    (newCard.begin_time > card.begin_time)
                ) {
                    return true;
                }

            } else if (card.days.includes(dayPlusOne) && card.begin_time < newCard.end_time) {
                return true;
            } else if (card.days.includes(dayMinusOne) && card.end_time > newCard.begin_time) {
                return true;
            }
        }
        return false;
    }

    // Visual of conflicts can be found here: https://gitlab.corp.netatmo.com/web/settingsvelux/snippets/508
    private checkSingleOvernightConflicts(card: ScheduleCard, newCard: ScheduleCard): boolean {
        /**
         * Determine which card is running overnight, and which is running during the day
         * Checking between daytime and overnight card prevents us from writing code twice:
         * Would need to flip for loops and if statements otherwise
         */
        const overnightCard = card.end_time < card.begin_time ? card : newCard;
        const daytimeCard = (card.end_time > card.begin_time) || (!card.end_time) ? card : newCard;
        for (const day of overnightCard.days) {
            /**
             *  If filtered card and newCard share same day, check timings
             *  Else if filtercard includes day + 1, check timings
             *  NOTE: If day is 6, Day + 1 should be 0 to loop to beginning of week
             *  Else, no conflicts
             */
            const dayPlusOne = day === 6 ? 0 : day + 1;
            if (daytimeCard.days.includes(day)) {
                /**
                 * If daytime card end time is greater than overnight begin time
                 * OR the begin time is greater than or equal the overnight cards beging time, potential conflict
                 * Else if daytime card begins before overnight ends
                 * Else, no conflicts
                 */
                if (overnightCard.begin_time < daytimeCard.end_time || daytimeCard.begin_time >= overnightCard.begin_time) {
                    return true;
                } else if (daytimeCard.begin_time < overnightCard.end_time) {
                    return true;
                }

            } else if (daytimeCard.days.includes(dayPlusOne) && daytimeCard.begin_time < overnightCard.end_time) {
                /**
                 * If daytime card begin time is less than overnight card end time, there is a potential conflict
                 */
                return true;
            }
        }
        return false;
    }



    /**
     *
     * @param scheduleCard
     * Function to determine if a the timing of a scheduleCard will conflict with current schedule
     */
    checkScheduleConflicts(newCard: ScheduleCard, algoMetadata, eventMetadata): ScheduleCard[] {

        /**
         * Conforming to specifications of function to allow checks to work properly
         */
        if (newCard.end_time === null) {
            newCard.end_time = undefined;
        }
        // First, retrieve current cards.
        // If scheduleCard has a card Id, filter old version of card from list so old version is not compared
        // Same card ID can exist in both schedules. Need to make sure we filter from correct list
        // Also filter all disabled cards
        let algoCards = [...algoMetadata];
        let eventCards = [...eventMetadata];
        if (newCard.algos) {
            algoCards = algoCards.filter((card: ScheduleCard) => (card.card_id !== newCard.card_id) && card.card_enabled);
        } else {
            eventCards = eventCards.filter((card: ScheduleCard) => (card.card_id !== newCard.card_id) && card.card_enabled);
        }
        const cards = [...algoCards, ...eventCards];

        /**
         * Function checks if card is ONLY hourly
         */
        function isCardHourly(card: ScheduleCard) {
            return (card.begin_time_ref === Enums.timings.HOURLY || !card.begin_time_ref) &&
                (card.end_time_ref === Enums.timings.HOURLY || !card.end_time_ref);
        }

        /**
         * Next filter all cards that do not create a conflict
         * If cards array contains card after filtering, we know we have a conflict
         */
        const conflictCards = cards.filter((card: ScheduleCard) => {
            /**
             * Conforming to specifications of function to allow checks to work properly
             */
            if (card.end_time === null) {
                card.end_time = undefined;
            }

            /** Determine if both cards are hourly cards */
            const areHourly = isCardHourly(card) && isCardHourly(newCard);

            /** If both cards are hourly, proceed down normal path */
            if (areHourly) {
                /**
                 * If neither card is running overnight, simple check
                 * Also, if one card has no end, and the other is daytime card, go here
                 * Also, if both cards have no end, go here
                 * Else, one card is running overnight. Complex checks
                 */
                if (
                    (card.begin_time < card.end_time && newCard.begin_time < newCard.end_time) ||
                    (card.begin_time < card.end_time && !newCard.end_time) ||
                    (newCard.begin_time < newCard.end_time && !card.end_time) ||
                    (typeof newCard.end_time === 'undefined' && typeof card.end_time === 'undefined')
                ) {
                    // If conflict exists, check to see if they can exist at the same time
                    if (this.checkSameDayConflicts(card, newCard)) {
                        // If they can exist at the same time, we do not want to add to the array
                        // Need to invert return of function so if they can't exist at same time, we add to array
                        return !this.canExistTogether(card, newCard);
                    }
                } else {
                    /**
                     * If both cards run overnight, handle checks
                     * Otherwise, determine which card runs overnight
                     */
                    if (card.end_time < card.begin_time && newCard.end_time < newCard.begin_time) {
                        // If conflict exists, check to see if they can exist at the same time
                        if (this.checkDoubleOvernightConflicts(card, newCard)) {
                            // If they can exist at the same time, we do not want to add to the array
                            // Need to invert return of function so if they can't exist at same time, we add to array
                            return !this.canExistTogether(card, newCard);
                        }
                    } else {
                        // If conflict exists, check to see if they can exist at the same time
                        if (this.checkSingleOvernightConflicts(card, newCard)) {
                            // If they can exist at the same time, we do not want to add to the array
                            // Need to invert return of function so if they can't exist at same time, we add to array
                            return !this.canExistTogether(card, newCard);
                        }
                    }
                }
                /** Handle if one or both cards involve Sunrise/Sunset timing */
            } else {

                if (this.checkSunriseSunsetConflicts(card, newCard)) {
                    return !this.canExistTogether(card, newCard);
                }

            }
        });

        if (conflictCards.length > 0) {
            return conflictCards;
        }

        return null;
    }



    // To be called when a Schedule Card is added to the Schedule
    // Will generate new Zones and Timetable after adding card by calulating Schedule
    public addScheduleCard(card: ScheduleCard, metadata): ScheduleCard[] {
        const meta = JSON.parse(JSON.stringify(metadata));
        meta.push(card);
        return meta.map((mCard, index) => {
            return { ...mCard, card_id: index };
        });
    }

    // TO be called when a Schedule Card is updated
    // Will generate new Zones and Timetable after update by calculating schedule
    public updateScheduleCard(cardUpdate: ScheduleCard, metadata): ScheduleCard[] {
        // Retrieve index of Card in metadata array
        const cardIndex = metadata.findIndex((card) => card.card_id === cardUpdate.card_id);
        // Merge info of update with card update to ensure we maintain certain attributes
        const oldCard = metadata[cardIndex];
        const meta = JSON.parse(JSON.stringify(metadata));
        meta[cardIndex] = { ...cardUpdate, room_id: oldCard.room_id };
        return meta;
    }


    // To be called when a Schedule Card is removed
    // Will generate new Zones and Timetable after deletion by calculating schedule
    public removeScheduleCard(cardId: number, metadata): ScheduleCard[] {
        // Retrieve index of card for proper deletion
        const meta = JSON.parse(JSON.stringify(metadata));
        const cardIndex = meta.findIndex((card) => card.card_id === cardId);
        meta.splice(cardIndex, 1);
        // Now, reset card_ids to ensure proper IDing
        // Then, calculate updated schedule
        return meta.map((card, index) => {
            return { ...card, card_id: index };
        });
    }


    // Calculates Zones and Timetable/Timeslots from Cards passed to function
    // Needs to be done to handle calculating  room algo schedules vs home/module events schedules
    // Calculating the schedules separate helps the embedded side
    public calculateSchedule(metadata: ScheduleCard[], gatewayId: string): BasicSchedule {
        /**
         * STEP 1 Retrieve all Schedule cards, and create empty Schedule type object
         * Should filter to retrieve only cards listed as card_enabled: true
         * Only want to generate active schedule for use by embedded
         * Copy and Parse to ensure no accidentl manipulation of current card data
         */
        const cards: ScheduleCard[] = JSON.parse(
            JSON.stringify(
                metadata.filter((card) => card.card_enabled)
            )
        );

        // Create empty schedule object to apply updates to
        const schedule: BasicSchedule = {
            zones: [],
            timetable: [],
            timetable_sunrise: [],
            timetable_sunset: []
        };

        /**
         * STEP 2 Generate tree of all offset times for timetable, and associated cards
         */
        const [hourlyCardOffsets, sunriseCardOffsets, sunsetCardOffsets] = this.generateOffsets(cards);
        /**
         * STEP 3 Iterate offsets/card sets and generate zones, and assign to offsets
         * Convert offset keys from strings to numbers, then sort offsets from least to greatest
         *  Example of hourlyCardOffsets at this point:
         *  {480: [2, 3, 4], 1340: [2, 3, 4, 7], 2280: [7] }
         * Example of sunrise/sunsetCardOffsets
         * (Primary key is day of week to execute, followed by subkey representing offset from Sunrise or Sunset):
         * {1: 120: [0], 5: 45: [3]}
         */
        const hourlyOffsets = Object.keys(hourlyCardOffsets).map((offset: string) => {
            return parseInt(offset, 10);
        }).sort((a, b) => a - b);

        // Get array of days for Sunrise/Sunset. Sub "Offset" values for each will be sorted later
        const sunriseDays = Object.keys(sunriseCardOffsets);

        const sunsetDays = Object.keys(sunsetCardOffsets);

        /** Iterate over hourlyOffsets to generate normal hourly schedule timetable */
        for (const offset of hourlyOffsets) {
            /**
             * Retrieve list of cards associtated to the offset
             * Cards are represented in the list by their index in the cards array
             */
            const cardIndecies = hourlyCardOffsets[offset] as number[];
            const zoneCards = cardIndecies.map((index) => {
                return cards[index];
            });

            /**
             * Iterate over cards to retrieve infromation to generate zone object
             * Zone object will include list of action objects
             */
            const zone = this.generateZone(zoneCards, offset, schedule.zones.length, Enums.timings.HOURLY, gatewayId);
            // FOR ALGOS: If zone.rooms is emtpy from algo correction, do not add zone, and do not make timetable entry
            if (zone.rooms && zone.rooms.length === 0) {
                continue;
            }

            /**
             * Now that Zone has been created, check to make sure same zone does not already exist
             * Need to deep compare new Zone object with existing Zone objects in zone array
             * Zone example: { id: 1, rooms: [ {id: 1, co2: true}, {id: 2, humidity: false }] }
             */
            if (schedule.zones.length === 0) {
                schedule.zones.push(zone);
                schedule.timetable.push({ zone_id: zone.id, m_offset: offset });
            } else {
                let matchedZone: Zone = null;
                /**
                 * Iterate over zones in array. If zone already exists, capture it and break loop
                 */
                for (let t = 0; t < schedule.zones.length; t++) {
                    /**
                     * Need to alter zone ID's temporarily
                     * By default they are generated with different ID's
                     * This will cause the compare to always identify them as different objects
                     */
                    const zone1 = { ...zone, id: 0 };
                    const zone2 = { ...schedule.zones[t], id: 0 };
                    if (isEqual(zone1, zone2)) {
                        matchedZone = schedule.zones[t];
                        break;
                    }
                }
                // If no zone was captured, zone does not exist yet. Push to zones array
                // We can then also assign this zone to the current offset in the timetable
                if (!matchedZone) {
                    schedule.zones.push(zone);
                    schedule.timetable.push({ zone_id: zone.id, m_offset: offset });
                } else {
                    // If zone was captured, assign this zone to the current offset in the timetable
                    schedule.timetable.push({ zone_id: matchedZone.id, m_offset: offset });
                }
            }

        }

        for (const day of sunriseDays) {
            /**
             * Structure of day:
             * { 120: [0,1], 45: [5,6]}
             * Must be sorted like previous offsets
             */
            const offsets = Object.keys(sunriseCardOffsets[day]).map((offset: string) => {
                return parseInt(offset, 10);
            }).sort((a, b) => a - b);

            /** Now, iterate through offsets to set zones and timetable */
            for (const offset of offsets) {

                /**
                 * Retrieve list of cards associtated to the offset
                 * Cards are represented in the list by their index in the cards array
                 */
                const cardIndecies = sunriseCardOffsets[day][offset] as number[];
                const zoneCards = cardIndecies.map((index) => {
                    return cards[index];
                });
                const [timetable, zones] = this.handleSunriseSunsetTimetable(
                    parseInt(day, 10),
                    offset,
                    schedule.zones,
                    zoneCards, Enums.timings.SUNRISE,
                    gatewayId
                );
                if (timetable === null || zones === null) {
                    continue;
                }
                schedule.timetable_sunrise = [...schedule.timetable_sunrise, ...timetable as TwilightTimetable[]];
                schedule.zones = [...schedule.zones, ...zones as Zone[]];
            }
        }

        for (const day of sunsetDays) {
            /**
             * Structure of day:
             * { 120: [0,1], 45: [5,6]}
             * Must be sorted like previous offsets
             */
            const offsets = Object.keys(sunsetCardOffsets[day]).map((offset: string) => {
                return parseInt(offset, 10);
            }).sort((a, b) => a - b);

            /** Now, iterate through offsets to set zones and timetable */
            for (const offset of offsets) {
                /**
                 * Retrieve list of cards associtated to the offset
                 * Cards are represented in the list by their index in the cards array
                 */
                const cardIndecies = sunsetCardOffsets[day][offset] as number[];
                const zoneCards = cardIndecies.map((index) => {
                    return cards[index];
                });
                const [timetable, zones] = this.handleSunriseSunsetTimetable(
                    parseInt(day, 10),
                    offset,
                    schedule.zones,
                    zoneCards,
                    Enums.timings.SUNSET,
                    gatewayId
                );
                if (timetable === null || zones === null) {
                    continue;
                }
                schedule.timetable_sunset = [...schedule.timetable_sunset, ...timetable as TwilightTimetable[]];
                schedule.zones = [...schedule.zones, ...zones as Zone[]];
            }
        }

        // STEP 4 Apply offsets and zones to current schedule
        return schedule;
    }


    generateZone(cards: ScheduleCard[], offset: number, id: number, timingType: string, gatewayId: string): Zone {
        /**
         * Iterate over cards to retrieve infromation to generate zone object
         * Zone object will include list of action objects
         */
        const zone = {
            id
        } as Zone;

        for (const card of cards) {

            // Algos will not always exist on Card. Might be module schedule or Home schedule
            if (card.algos) {

                // Need to add rooms array to zone if no rooms array exists yet
                if (!zone.rooms) {
                    zone.rooms = [];
                }

                /**
                 * if timing type is Hourly, do original check to see if offset is in offset list
                 * If offset is included in cards endOffsets array, we know these actions will be ending actions
                 * Otherwise, check to see if passed timing type is the end timing for the card. This is necessary for sunrise/sunset
                 */
                let isEnd: boolean;
                if (timingType !== Enums.timings.HOURLY) {
                    isEnd = card.end_time_ref === timingType;
                } else {
                    isEnd = card.endOffsets.includes(offset);
                }

                /**
                 * Algos array is a list of strings that represents action keys
                 * Assign these algos as attributes of the actionObj with true or false vals
                 */
                const actionObj = {
                    id: card.room_id.toString(),
                } as ScheduleAction;
                for (const algo of card.algos) {
                    /**
                     * Algo should be true if start, false if end
                     * isEnd will be true if end, false if start. So we invert the val
                     * We then push the action to the array in the Zone
                     */

                    actionObj[algo] = !isEnd;
                }
                // /**
                //  * In some sames, cards will share the same end or beginning time
                //  * We do not want to push the same action twice into the zone
                //  * This check below ensures that the current action does not exist already in the zone
                //  */
                const objIndex = zone.rooms.findIndex((room) => room.id.toString() === actionObj.id.toString());
                if (objIndex >= 0) {
                    // Check to see if both action objects contain the same key
                    const duplicateAlgos = this.checkDoubleAlgo(actionObj, zone.rooms[objIndex]);
                    // If they contain the duplicate algo keys, determine if action is needed
                    if (duplicateAlgos) {
                        // Iterate over all duplicate algos
                        for (const dup of duplicateAlgos) {
                            // Get values from each object
                            const val1 = actionObj[dup];
                            const val2 = zone.rooms[objIndex][dup];
                            // If one value is true, and the other is false, we should remove both keys from the object
                            // This essentially creates a continuation of the schedule, despite having one card end, and the other continue
                            // No need to handle if they are the same value
                            // Merging both objects will solve this issue since only one key can exist
                            if (val1 !== val2) {
                                delete actionObj[dup];
                                delete zone.rooms[objIndex][dup];
                            }
                        }
                    }

                    // Merge both objects together not that conflicting
                    zone.rooms[objIndex] = { ...zone.rooms[objIndex], ...actionObj };

                    // If we just deleted only other key than ID from object, we should remove object from zone.rooms
                    if (Object.keys(zone.rooms[objIndex]).length === 1) {
                        zone.rooms.splice(objIndex, 1);
                    }

                } else {
                    zone.rooms.push(actionObj);
                }
            }

            // Modules for Module actions will not always exist on Card
            if (card.modules) {
                // Need to add modules array to zone if no modules array exists yet
                if (!zone.modules) {
                    zone.modules = [];
                }

                // Windows have the unique ability to set an auto close time
                // This in turn creates an "end time" for their event
                // This needs to be handled
                const isEnd = typeof card.endOffsets !== 'undefined' ? card.endOffsets.includes(offset) : false;

                // If isEnd is true, and modules for card includes things other than window, we need to filter them out
                let modules = JSON.parse(JSON.stringify(card.modules)) as ModulesAction[];
                if (isEnd) {
                    // Only windows should be focused on endOffset
                    modules = modules.filter((moduleAction) => {
                        return moduleAction.isWindow;
                    });
                }


                // Iterate over card modules, and deep copy to prevent affecting data on card
                for (const module of modules as ModulesAction[]) {
                    // If this is an end offset, we should only apply those windows who are ending
                    // All others should not be added to the zone, since their target position is not changing
                    // If not end offset, add modules as normal
                    if (isEnd && module.isWindow) {
                        // Since this is an end offset, we are auto closing the windows
                        // Set target position to 0
                        module.target_position = 0;
                    }

                    // Should check if module already exists in zone array.
                    const existsIndex = zone.modules.findIndex((zoneMod) => module.id === zoneMod.id);
                    // If module exists in zone, we need to systematically determine which module event will stay in zone
                    // Backend only allows a module to exist in a given zone once, so we cannot have two events for the same module
                    // Otherwise, simply push zone module
                    if (existsIndex >= 0) {
                        // If module is Window, update zone module value to whichever module event CLOSES the window the most
                        // If blind or shutter, we need to handle BOTH target_position AND target_orientation, if it exists
                        // Ultimately, these are the same values since CLOSE for windows and OPEN for blinds/shutters are both 0
                        // Should take which ever value OPENS the blind/shutters the most
                        const targetValue = module.target_position < zone.modules[existsIndex].target_position ?
                            module.target_position :
                            zone.modules[existsIndex].target_position;
                        zone.modules[existsIndex].target_position = targetValue;
                        // Now, handle if target_orientation exists
                        // Follows same principles as blinds and shutters
                        if (typeof module.target_orientation !== 'undefined') {
                            const targetOrientation = module.target_orientation < zone.modules[existsIndex].target_orientation ?
                                module.target_orientation :
                                zone.modules[existsIndex].target_orientation;
                            zone.modules[existsIndex].target_orientation = targetOrientation;
                        }
                    } else {
                        // Remove isWindow and isBlindShutter, they are not needed for the zone, only for metadata and calculation
                        delete module.isWindow;
                        delete module.isBlindShutter;
                        zone.modules.push(module);
                    }

                }
            }

            // Scenario for Home actions will not always exist on Card
            if (card.scenario) {
                /**
                 * Scenario action objects are in the same array as modules
                 * Need to add modules array to zone if no modules exists yet
                 */
                if (!zone.modules) {
                    zone.modules = [];
                }

                const actionObj = {
                    id: gatewayId,
                    scenario: card.scenario
                } as ModulesAction;
                zone.modules.push(actionObj);
            }
        }
        return zone;
    }


    generateOffsets(cards: ScheduleCard[]) {

        let hourlyCardOffsets = {};
        /**
         * There is special handling for Sunrise/Sunset offsets, so we need to calculate separately
         * Note: We should not have instances where schedule runs Sunrise -> Sunrise/Sunset -> Sunset
         * The UI should block the above case
         */
        const sunriseCardOffsets = {};
        const sunsetCardOffsets = {};

        /** Function to handle pushing of start offsets for Sunrise/Sunset */
        function pushStartSunriseSunetDay(offset: number, day: number, cardIndex: number, timing: string) {
            const pushObject = timing === Enums.timings.SUNRISE ? sunriseCardOffsets : sunsetCardOffsets;
            /**
             * Check to see if offset value exists in object
             * If not, add offset as attribute, and add index of card to list
             * Card index results in faster lookup compared to finding card_id
             * O(1) vs O(n)
             */
            if (!pushObject[day]) {
                pushObject[day] = {
                    [offset]: [cardIndex]
                };
            } else {
                // Check to see if offset has been listed in day already
                // If not, add it
                if (!pushObject[day][offset]) {
                    pushObject[day][offset] = [cardIndex];
                } else {
                    pushObject[day][offset].push(cardIndex);
                }
            }
        }

        /**
         * Function to handle the pushing of start offsets for all timetable types
         */
        function pushStartOffset(card: ScheduleCard, day: number, cardIndex: number) {
            let startOffset = card.begin_time + (day * (24 * 60));

            /**
             * Need to handle 24h, 7 day a week schedules
             * Should take cards with same begin and end time
             * And Cards with 0 and 1440 start -> end times
             * And instead put a start time of 0h Monday to start schedule immediately
             * Otherwise, handle schedules normally
             */
            if (
                (card.begin_time === card.end_time || (card.begin_time === 0 && card.end_time === 1440))
            ) {
                /** If schedule is 7 days a week, set start to Monday at 00:00 */
                /**
                 * Or, if it is 24h schedule, but not first day of schedule
                 * OR if day is monday, and Sunday is also active
                 * We do not want to add other start times, as we only need first start, and last end time
                 */
                if (card.days.length === 7) {
                    startOffset = 0;
                } else if (card.days.includes(day - 1) || (day === 0 && card.days.includes(6))) {
                    return;
                }
            }
            /**
             * Check to see if offset value exists in object
             * If not, add offset as attribute, and add index of card to list
             * Card index results in faster lookup compared to finding card_id
             * O(1) vs O(n)
             */
            if (!hourlyCardOffsets[startOffset]) {
                hourlyCardOffsets[startOffset] = [cardIndex];
            } else {
                hourlyCardOffsets[startOffset].push(cardIndex);
            }
        }

        /**
         * Function to handle the creation and pushing of end offsets for sunrise/sunset offsets
         */
        const pushSunriseSunsetEndOffset = (card: ScheduleCard, day: number, cardIndex: number, timing: string) => {
            let endDay = day;
            const pushObject = timing === Enums.timings.SUNRISE ? sunriseCardOffsets : sunsetCardOffsets;
            /**
             * If card endref is sunrise, and Sunset is begin ref, we know card runs overnight
             * Need to update day accordingly
             * Also, if card runs from hourly -> Sunrise, and the card hourly timing is After 12PM, consider it overnight
             * And adjust the day
             */
            if (
                card.begin_time_ref === Enums.timings.SUNSET ||
                (
                    card.begin_time_ref === Enums.timings.HOURLY &&
                    card.end_time_ref === Enums.timings.SUNRISE &&
                    card.begin_time > 720
                )
            ) {
                /**
                 * If day is equal to 7, and endref is sunrise, we know card will run overnight from Sunday -> Monday
                 * Need to change day to 1 to reflect this
                 */
                endDay = day + 1;
                if (day === 7) {
                    endDay = 1;
                    /**
                     * At the request of Embedded, we also need to inject a turnoff event at Sunday 24h and turnon event at Monday 0h
                     * This ensures correct calculation of schedule for a new week, even for Sunrise/Sunset
                     */
                    this.handleSundayMondayRollover(card, cardIndex, hourlyCardOffsets);
                }
            }
            /**
             * Check to see if offset value exists in object
             * If not, add offset as attribute, and add index of card to list
             * Card index results in faster lookup compared to finding card_id
             * O(1) vs O(n)
             */
            if (!pushObject[endDay]) {
                pushObject[endDay] = {
                    [card.end_time]: [cardIndex]
                };
            } else {
                // Check to see if offset has been listed in endDay already
                // If not, add it
                if (!pushObject[endDay][card.end_time]) {
                    pushObject[endDay][card.end_time] = [cardIndex];
                } else {
                    pushObject[endDay][card.end_time].push(cardIndex);
                }
            }
            card.endOffsets.push(card.end_time);
        };

        cards.forEach((card: ScheduleCard, cardIndex: number) => {
            /**
             * Generate true timeslot Offset times based on what days card is active
             * Formula example: being_time + (day * 1440)
             * 1440 is equal to 24 hours * 60 minutes (aka the amount of minutes in a day)
             * Then add offset to object along with index of card in ScheduleCards array
             * We need this refernce to the card to correctly generate zones
             */
            for (const day of card.days) {
                /**
                 * Separate cards by starting reference
                 * Cannot have all put into same object without impacting the final zone object calculation
                 * Thanks to this, same basic calculations are performed here repeticiously
                 */
                if (card.begin_time_ref === Enums.timings.HOURLY || !card.begin_time_ref) {

                    pushStartOffset(card, day, cardIndex);

                } else if (card.begin_time_ref === Enums.timings.SUNRISE) {
                    /** Need to adjust day for Sunrise/Sunset. Values between 1-7 instead of 0-6 */
                    const setday = day + 1;
                    pushStartSunriseSunetDay(card.begin_time, setday, cardIndex, Enums.timings.SUNRISE);

                } else if (card.begin_time_ref === Enums.timings.SUNSET) {
                    /** Need to adjust day for Sunrise/Sunset. Values between 1-7 instead of 0-6 */
                    const setday = day + 1;
                    pushStartSunriseSunetDay(card.begin_time, setday, cardIndex, Enums.timings.SUNSET);
                }

                /**
                 * Need to check for card end_time attribute
                 * Event schedule cards will not include end_time since they are single shot events
                 */
                if (typeof card.end_time !== 'undefined' && card.end_time !== null) {

                    // Establish endOffset list. Reason for existing written below
                    card.endOffsets = !card.endOffsets ? [] : card.endOffsets;

                    if (card.end_time_ref === Enums.timings.HOURLY || !card.end_time_ref) {

                        const offsets = this.pushHourlyEndOffset(card, day, cardIndex, hourlyCardOffsets);
                        hourlyCardOffsets = { ...hourlyCardOffsets, ...offsets };

                    } else if (card.end_time_ref === Enums.timings.SUNRISE || card.end_time_ref === Enums.timings.SUNSET) {
                        /** Need to adjust day for Sunrise/Sunset. Values between 1-7 instead of 0-6 */
                        const setday = day + 1;
                        pushSunriseSunsetEndOffset(card, setday, cardIndex, card.end_time_ref);

                    }
                }
            }
        });
        /** Return all offset Objects */
        return [hourlyCardOffsets, sunriseCardOffsets, sunsetCardOffsets];
    }


    private pushHourlyEndOffset(card: ScheduleCard, day: number, cardIndex: number, currentOffsets: any) {

        const hourlyCardOffsets = { ...currentOffsets };
        /**
         * If  end < begin, need to change end time calculation to be day + 1
         * However, if day === 6, we need to loop back to beginning of week (0)
         */
        let endOffset = card.end_time + (day * (24 * 60));

        /** If end_time is less than begin time, OR begin time is sunset, and end time is before 12pm, adjust day + 1 */
        if (
            card.end_time < card.begin_time ||
            (card.begin_time_ref === Enums.timings.SUNSET && card.end_time <= 720)
        ) {
            let endDay = day + 1;
            /**
             * If day is equal to 6, we also need to push offsets at 10080 on day 6, and 0 on day 0 for embedded
             * 10080 will be populated with events to quickly "turn off" these events that bridge days
             * 0 will be populated with events to quickly "turn back on"
             * These occur to ensure the previous week "ends" and the new week "begins"
             * Also helps embedded ensure there is no "action"
             * or "schedule" leakage (where some actions are never turned off)
             */
            if (day === 6) {
                endDay = 0;
                this.handleSundayMondayRollover(card, cardIndex, hourlyCardOffsets);
            }
            endOffset = card.end_time + (endDay * (24 * 60));
            /**
             * Need to handle 24h, 7 day a week schedules
             * Should take cards with same begin and end time
             * And Cards with 0 and 1440 start -> end times
             * And instead put a start time of 0h Monday to start schedule immediately
             * Otherwise, handle schedules normally
             */
        } else if (
            (card.begin_time === card.end_time || (card.begin_time === 0 && card.end_time === 1440))
        ) {
            /** If schedule is 7 days a week, set start to Monday at 00:00 */
            /**
             * Or, if it is 24h schedule, and the next day is included in the schedule, we should skip it
             * We do not want to add other end times, as we only need first start, and last end time
             */
            if (card.days.length === 7) {
                endOffset = 1440 * 7;
            } else if (card.days.includes(day + 1)) {
                return hourlyCardOffsets;
            } else {
                let endDay = day + 1;
                /**
                 * If day is equal to 6, we also need to push offsets at 10080 on day 6, and 0 on day 0 for embedded
                 * 10080 will be populated with events to quickly "turn off" these events that bridge days
                 * 0 will be populated with events to quickly "turn back on"
                 * These occur to ensure the previous week "ends" and the new week "begins"
                 * Also helps embedded ensure there is no "action"
                 * or "schedule" leakage (where some actions are never turned off)
                 */
                if (day === 6) {
                    endDay = 0;
                    // 10080 === 7 * 1440
                    const end = 7 * (24 * 60);
                    // Add to endOffsets to calculate zones later
                    card.endOffsets.push(end);
                    // If this is not already part of offsets object, add it
                    // Otherwise push
                    if (!hourlyCardOffsets[end]) {
                        hourlyCardOffsets[end] = [cardIndex];
                    } else {
                        hourlyCardOffsets[end].push(cardIndex);
                    }

                    /**
                     * We don't want to add "Turn on" if 24h schedule ends at midnight Monday
                     */
                    if (card.end_time === 0 && !card.days.includes(0)) {
                        return hourlyCardOffsets;
                    } else {
                        // Now we need to add the "turn on" offset back to the offsets object at 0
                        if (!hourlyCardOffsets[0]) {
                            hourlyCardOffsets[0] = [cardIndex];
                        } else {
                            hourlyCardOffsets[0].push(cardIndex);
                        }
                    }

                    /**
                     * We don't want to add day timing if we are not actually at an end state
                     */
                    if (card.days.includes(0)) {
                        return hourlyCardOffsets;
                    } else {
                        endOffset = card.end_time + (endDay * (24 * 60));
                    }
                } else {
                    endOffset = card.end_time + (endDay * (24 * 60));
                }
            }
        }

        /**
         * Repeate same process as startOffset for endOffset
         * Also push offset to temp card attribute endOffsets list
         * We do this to better determine later when to apply end actions in each zone
         */
        if (!hourlyCardOffsets[endOffset]) {
            hourlyCardOffsets[endOffset] = [cardIndex];
        } else {
            hourlyCardOffsets[endOffset].push(cardIndex);
        }
        card.endOffsets.push(endOffset);
        return hourlyCardOffsets;
    }

    private handleSundayMondayRollover(card: ScheduleCard, cardIndex: number, hourlyCardOffsets: any) {
        // 10080 === 7 * 1440
        const end = 7 * (24 * 60);
        // Add to endOffsets to calculate zones later
        card.endOffsets.push(end);
        // If this is not already part of offsets object, add it
        // Otherwise push
        if (!hourlyCardOffsets[end]) {
            hourlyCardOffsets[end] = [cardIndex];
        } else {
            hourlyCardOffsets[end].push(cardIndex);
        }

        // Now we need to add the "turn on" offset back to the offsets object at 0
        if (!hourlyCardOffsets[0]) {
            hourlyCardOffsets[0] = [cardIndex];
        } else {
            hourlyCardOffsets[0].push(cardIndex);
        }
    }


    handleSunriseSunsetTimetable(day: number, offset: number, zones: Zone[], zoneCards: ScheduleCard[], timingType: string, gatewayId: string) {
        const timetable: TwilightTimetable[] = [];
        const endZones: Zone[] = [];
        /**
         * Iterate over cards to retrieve infromation to generate zone object
         * Zone object will include list of action objects
         */
        const zone = this.generateZone(zoneCards, offset, zones.length, timingType, gatewayId);

        // FOR ALGOS: If zone.rooms is emtpy from algo correction, do not add zone, and do not make timetable entry
        if (zone.rooms && zone.rooms.length === 0) {
            return [null, null];
        }

        /**
         * Now that Zone has been created, check to make sure same zone does not already exist
         * Need to deep compare new Zone object with existing Zone objects in zone array
         * Zone example: { id: 1, rooms: [ {id: 1, co2: true}, {id: 2, humidity: false }] }
         */
        if (zones.length === 0) {
            endZones.push(zone);

            const timeslot = { zone_id: zone.id, day } as TwilightTimetable;
            if (offset !== 0) {
                timeslot.twilight_offset = offset;
            }
            timetable.push(timeslot);
        } else {
            let matchedZone: Zone = null;
            /**
             * Iterate over zones in array. If zone already exists, capture it and break loop
             */
            for (let t = 0; t < zones.length; t++) {
                /**
                 * Need to alter zone ID's temporarily
                 * By default they are generated with different ID's
                 * This will cause the compare to always identify them as different objects
                 */
                const zone1 = { ...zone, id: 0 };
                const zone2 = { ...zones[t], id: 0 };
                if (isEqual(zone1, zone2)) {
                    matchedZone = zones[t];
                    break;
                }
            }
            // If no zone was captured, zone does not exist yet. Push to zones array
            // We can then also assign this zone to the current offset in the timetable
            if (!matchedZone) {
                endZones.push(zone);

                const timeslot = { zone_id: zone.id, day } as TwilightTimetable;
                if (offset) {
                    timeslot.twilight_offset = offset;
                }
                timetable.push(timeslot);
            } else {
                // If zone was captured, assign this zone to the current offset in the timetable
                const timeslot = { zone_id: matchedZone.id, day } as TwilightTimetable;
                if (offset) {
                    timeslot.twilight_offset = offset;
                }
                timetable.push(timeslot);
            }
        }

        return [timetable, endZones];
    }

    checkDoubleAlgo(algoObj1: ScheduleAction, algoObj2: ScheduleAction) {
        // Deep copy data so we do not affect original data
        algoObj1 = JSON.parse(JSON.stringify(algoObj1));
        algoObj2 = JSON.parse(JSON.stringify(algoObj2));
        // Remove ID attribute so we do not get a false positive
        delete algoObj1.id;
        delete algoObj2.id;
        // First get list of Algo Keys
        const keys1 = Object.keys(algoObj1);
        const keys2 = Object.keys(algoObj2);
        // Check for duplicate keys in string
        const hasDuplicate = /\b(\w+)\b.*\b\1\b/g.test(keys1.join(' ') + ' ' + keys2.join(' '));
        // If duplicate exists, iterate over keys to determine duplicate key
        if (hasDuplicate) {
            // There may be more than one duplicate key
            const duplicates = [];
            for (const key of keys1) {
                if (typeof algoObj2[key] !== 'undefined') {
                    duplicates.push(key);
                }
            }
            return duplicates;
        } else {
            return null;
        }
    }



    /**
   * Function that is used to create an object containing options from from -2 hours (-120 minutes) through 2 hours (120 minutes)
   * Around Sunrise/Sunset
   * Returns this object to be used in dropdown selector
   * @param key sunrise or sunset key, depending on which version is being generated
   * @param time sunrise or sunset time, depending on which version is being generated
   */
    generateValues(key: string, time: string, lang: LangPipe) {
        // Create empty map. Using map to preserve placement order
        const obj = new Map();
        // Iterate from -2 hours (-120 min) to 2 hours (120 min), representing before and after sunrise/sunset
        for (let i = Enums.ScheduleEnums.MIN_SUN_OFFSET; i <= Enums.ScheduleEnums.MAX_SUN_OFFSET; i += Enums.ScheduleEnums.SUN_OFFSET_STEP) {

            // If we are less than -55 or greater than 55, get hour, otherwise use null value;
            const hour = Math.abs(i) / 60 >= 1 ? Math.floor(Math.abs(i) / 60).toString() : null;
            // Get minute value, as long as value isn't 0. If 0, return null value
            const minute = i !== 0 ? Math.abs(i) % 60 : null;
            // Now generate label to display in select list
            let label = '';
            // If value is 0, generate special 0 value label
            if (i === 0) {
                label = `${lang.transform(key)} (${time})`;
            } else {
                // If value is positive, label should indicate offset will occur AFTER
                // Otherwise, offset will occur BEFORE
                if (Math.sign(i) === 1) {
                    // tslint:disable-next-line: max-line-length
                    label = `${hour ? hour + ' ' + lang.transform('common-settings.__HOUR') : ''} ${minute ? lang.transform('app.__MINUTE_FORMAT', [minute.toString()]) : ''} ${lang.transform('settings.__MIN_AFTER')}`;
                } else {
                    // tslint:disable-next-line: max-line-length
                    label = `${hour ? hour + ' ' + lang.transform('common-settings.__HOUR') : ''} ${minute ? lang.transform('app.__MINUTE_FORMAT', [minute.toString()]) : ''} ${lang.transform('settings.__MIN_BEFORE')}`;
                }
            }
            // Add label to map
            obj.set(i, label);
        }

        // Return select list map
        return obj;
    }


    getSunriseValue(homeCoordinates: number[]) {
        const time = getSunrise(homeCoordinates[1], homeCoordinates[0]).toLocaleTimeString('fr-FR');
        const newTime = time.substr(0, time.lastIndexOf(':'));
        return newTime;
    }

    getSunsetValue(homeCoordinates: number[]) {
        const time = getSunset(homeCoordinates[1], homeCoordinates[0]).toLocaleTimeString('fr-FR');
        const newTime = time.substr(0, time.lastIndexOf(':'));
        return newTime;
    }


    calculateEndtime(beginTime: number, endTime: number) {
        if (typeof endTime !== 'undefined' && endTime !== null && endTime !== 0) {
            // This handles if value is greater than a full day (aka ends overnight)
            // If greater than 1440, the modulus returns the correct endtime
            return (beginTime + endTime % 1440);
        }
        return null;
    }



}


/**
     * @description Function to handle pruneing and processing of any bad card data post bugs
     * @param cards - ScheduleCard array to be triaged in case of bad data
     * @returns cards - List of healthy schedule cards
*/
export const cardProcessing = (cards: ScheduleCard[], gateway: Module, rooms: Room[]) => {
    return cards.reduce<ScheduleCard[]>((goodCards, card) => {
        // First, if card is a module action card, make sure begin and end ref are the same value
        // It is illegal for a Module Action card to have different refs. Only Algo cards may have different begin/end refs
        if (card.modules && card.begin_time_ref !== card.end_time_ref) {
            card.end_time_ref = card.begin_time_ref;
        }
        /**
         * Evaluate if card is bad/should be kept in schedule metadata
         * If yes, also check card to check/correct if card has Sunrise/Sunset issues
         */
        if (shouldKeepCard(card, gateway, rooms) && correctSunriseSunsetOffsets(card)) {
            goodCards.push(card);
        }
        return goodCards;
    }, []);
}

/**
 * @description Evalautes passed ScheduleCard to determine if card should be kept in user Schedule.
 * @param card - Schedule card to be evaluated
 * @returns boolean - False if should be deleted, true if card is good
 */
export const correctSunriseSunsetOffsets = (card: ScheduleCard) => {
    // Ensure card has time refs for this calculation/comparison
    // If a ref is undefined, it defaults to Hourly (original Schedule code did not include refs, and was only hourly)
    card.begin_time_ref = card.begin_time_ref || Enums.timings.HOURLY;
    card.end_time_ref = card.end_time_ref || Enums.timings.HOURLY;

    // Check if card begin time reference is sunrise or sunset. If it's hourly, pass over. Cards should always have begin time ref
    if (
        card.begin_time_ref === Enums.timings.SUNRISE || card.begin_time_ref === Enums.timings.SUNSET
    ) {
        // If card begin_time (aka Sunrise/Sunset offset) is outside of max/min bounds, 0 the offset
        // if (card.begin_time > ScheduleEnums.MAX_SUN_OFFSET || card.begin_time < ScheduleEnums.MIN_SUN_OFFSET) {
        // Temporarily hard-coding until embedded/backend fixes are in place
        if (card.begin_time > 120 || card.begin_time < -120) {
            return false;
        }
    }

    // Next, handle card end_time_ref issues, if card is sunrise or sunset
    if (
        card.end_time_ref === Enums.timings.SUNRISE || card.end_time_ref === Enums.timings.SUNSET
    ) {
        // If card end_time (aka Sunrise/Sunset offset) is outside of max/min bounds, 0 the offset
        // if (card.end_time > ScheduleEnums.MAX_SUN_OFFSET || card.end_time < ScheduleEnums.MIN_SUN_OFFSET) {
        // Temporarily hard-coding until embedded/backend fixes are in place
        if (card.end_time > 120 || card.end_time < -120) {
            return false;
        }
    }

    return true;
}

/**
 * Function to remove cards from metadata that include invalid room or moduleID's
 * We do this to not impact schedule generation.
 * NOTE: THIS FUNCTION SHOULD BE TEMPORARY. BACKEND SHOULD IMPLEMENT THIS FIX ON THEIR END FOR PROPER DATA MANAGEMENT
 */
export const shouldKeepCard = (card: ScheduleCard, gateway: Module, rooms: Room[]) => {
    /** First get array of all roomIds in Home  */
    const roomIds = rooms.map(r => r.id)

    /**
     * Next, iterate over all cards, and filter out bad cards
     * Bad card conditions:
     * Room ID of card does not exist in Room ID's of Home
     * Module ID in card does not exist in Room of card
     */
    /** If Card doesn't include RoomId, its a home card, and is automatically good */
    if (typeof card.room_id !== 'undefined') {


        /** If Card RoomID doesn't exist in Home, throw out card */
        if (!roomIds.includes(card.room_id.toString())) {
            return false;
        }

        /**
         * If Card is Modules, filter modules
         * If Card is Algos and has reached this point, it is clean
         */
        if (card.modules) {
            /** Retrieve Card Room and  ID's of Modules in Room */
            const room: Room = rooms.find(r => r.id === card.room_id.toString());
            const roomModuleIds = room.modules || []

            /** Filter all bad module entries from the card */
            /**
             * If gateway has been replaced in  home, and modules still exist,
             * change gateway/bridge id on card to be new gateway
             */
            const cardmodules = card.modules.filter(
                (module: ModulesAction) => roomModuleIds.includes(module.id)
            ).map((module: ModulesAction) => {
                if (module.bridge !== gateway.id) {
                    module.bridge = gateway.id;
                }
                return module;
            });
            /** set card.modules to the filtered modules */
            card.modules = cardmodules;
            /** If card modules is empty, throw out card */
            if (card.modules.length === 0) {
                return false;
            }
        }

    }

    return true;
}



