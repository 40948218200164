import { Injectable } from "@angular/core";
import { EnumHomesActions, GetHomesSuccess } from "@library/store/homes/homes.action";
import { SettingsState } from "@library/utils/interfaces/settings-state.interface";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { CreateNewSchedule, CreateNewScheduleFailure, CreateNewScheduleSuccess, LoadScheduleVelux, LoadScheduleVeluxSuccess, UpdateSchedule, UpdateScheduleFailure, UpdateScheduleSuccess } from "./schedules.action";
import { SchedulesService } from '@library/store/schedules/schedules.service';

@Injectable()
export class SchedulesEffectsVelux {
  constructor(
    private actions$: Actions,
    private store: Store<SettingsState>,
    private schedulesService: SchedulesService
  ) { }

  loadSchedule$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomesSuccess>(EnumHomesActions.GetHomesSuccess),
    switchMap((action) => {
      return [LoadScheduleVeluxSuccess({homes: action.payload})]
    })
  ))


  createSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(CreateNewSchedule),
    switchMap((action) => {
      if(action.data.isNew){
        return this.schedulesService.createNewSchedule(action.data).pipe(
          map(() => CreateNewScheduleSuccess({data: action.data})),
          catchError(({error}) => of(CreateNewScheduleFailure({error})))
        )
      }else{
        return this.schedulesService.modifySchedule(action.data).pipe(
          map(() => CreateNewScheduleSuccess({data: action.data})),
          catchError(({error}) => of(CreateNewScheduleFailure({error})))
        )
      }
            
    })
  ))

  updateSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateSchedule),
    switchMap((action) => {
      return this.schedulesService.modifySchedule(action.data).pipe(
        map(() => UpdateScheduleSuccess({data: action.data})),
        catchError(({error}) => of(UpdateScheduleFailure({error})))
      )
    })
  ))


}


