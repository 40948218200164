import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { Home } from '@library/store/homes/homes.interface';
import { PageLoaderFacade } from '@library/store/page-loader/page-loader.facade';
import { isDefined } from '@library/utils/helpers/store/is-defined';
import { HeaderService } from '@library/utils/services/header.service';
import { firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-home-information',
  templateUrl: './home-information.component.html',
  styleUrls: ['./home-information.component.scss']
})
export class HomeInformationComponent implements OnInit {


  home$ = this.homesFacade.currentHome$.pipe(isDefined());

  constructor(
    private header: HeaderService,
    private homesFacade: HomesFacade,
    private pageLoaderFacade: PageLoaderFacade,
    private titleService: Title
  ) { 
  }

  async ngOnInit() {
    this.pageLoaderFacade.handlePageLoader()
    const home = await firstValueFrom(this.home$);
    this.header.push({title: home.name});
    this.titleService.setTitle('Home advanced settings')
  }


  /**
   * Prettify the timezone from store: Europe/Isle_Of_Man -> Europe, Isle Of Man
   * @param home Current home
   */
   getHomeTimezonePretty(home: Home): string {
    return home.place.timezone.replace(/\//g, ', ').replace(/_/g, ' ');
  }

}

