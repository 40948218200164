import { HomeData } from "@library/store/homes/homes.interface"
import { createAction, props } from "@ngrx/store"
import { ScheduleCard, ScheduleVelux } from "./schedules.interface"


export const LoadScheduleVelux = createAction(
  '[Schedule] load schedule velux',
  props<{home_id: string}>()
)

export const LoadScheduleVeluxSuccess = createAction(
  '[Schedule] load schedule velux success',
  props<{homes: HomeData[]}>()
)


export const LoadScheduleVeluxFailure = createAction(
  '[Schedule] load schedule velux failure',
  props<{error: string}>()
)

export const SetCurrentScheduleCard = createAction(
  '[Schedule] set current schedule card',
  props<{card: ScheduleCard}>()
)


export const SetScheduleCardData = createAction(
  '[Schedule] set schedule card data',
  props<{data: ScheduleCard}>()
)

export const SetScheduleCardDataSuccess = createAction(
  '[Schedule] set schedule card data success',
)

export const SetScheduleCardDataFailure = createAction(
  '[Schedule] set schedule card data failure',
)



export const CreateNewSchedule = createAction(
  '[Schedule] create new schedule',
  props<{data: ScheduleVelux}>()
)


export const CreateNewScheduleSuccess = createAction(
  '[Schedule] create new schedule success',
  props<{data: ScheduleVelux}>()
)


export const CreateNewScheduleFailure = createAction(
  '[Schedule] create new schedule failure',
  props<{error: string}>()
)


export const UpdateSchedule = createAction(
  '[Schedule] update schedule',
  props<{data: ScheduleVelux}>()
)


export const UpdateScheduleSuccess = createAction(
  '[Schedule] update schedule success',
  props<{data: ScheduleVelux}>()
)


export const UpdateScheduleFailure = createAction(
  '[Schedule] update schedule failure',
  props<{error: string}>()
)


export const DeleteSchedule = createAction(
  '[Schedule] delete schedule',
  props<{data: ScheduleVelux}>()
)


export const DeleteScheduleSuccess = createAction(
  '[Schedule] delete schedule success',
  props<{data: ScheduleVelux}>()
)


export const DeleteScheduleFailure = createAction(
  '[Schedule] delete schedule failure',
  props<{error: string}>()
)