import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";


const routes: Routes = [
    {
        path: 'test',
        loadChildren: () => import('./views/homes-list-view/homes-list-view.module').then(m => m.HomesListViewModule)
    },
    {
        path: 'home/:home_id',
        loadChildren: () => import('./views/home-page/home-page.module').then(m => m.HomePageModule)
    },
    {
        path: 'users/:home_id',
        loadChildren: () => import('./views/users-page/users-page.module').then(m => m.UsersPageModule)
    },
    {
        path: 'modules-settings/:home_id/:module_id',
        loadChildren: () => import('./views/modules-settings-page/modules-settings-page.module').then(m => m.ModuleSettingsPageModule)
    },
    {
        path: 'calibration/:home_id/:module_id',
        loadChildren: () => import('./views/calibration-view/calibration-view.module').then(m => m.CalibrationViewModule)
    },
    {
        path: 'actuator/:home_id/:module_id',
        loadChildren: () => import('./views/actuator-page/actuator-page.module').then(m => m.ActuatorPageModule)
    },
    {
        path: 'notifications/:home_id',
        loadChildren: () => import('./views/notifications-page/notifications-page.module').then(m => m.NotificatPageModule)
    },
    {
        path: 'automations/:home_id',
        loadChildren: () => import('./views/schedule-automations-page/schedule-automations-page.module').then(m => m.ScheduleAutomationsPageModule)
    },
    {
        path: 'scenario/:home_id',
        loadChildren: () => import('./views/scenarios-page/scenarios-page.module').then(m => m.ScenariosPageModule)
    },
    {
        path: 'first-time-access/:home_id',
        loadChildren: () => import('./views/first-time-schedule-access-page/first-time-schedule-access-page.module').then(m => m.FirstTimeAccessSchedulePageModule)
    },
    {
        path: 'homecontrol/:home_id',
        redirectTo: '/update-app', 
        pathMatch: 'full'
    },
    {
        path: 'update-app',
        loadChildren: () => import('./views/update-app/update-app.module').then(m => m.UpdateAppModule)
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        }),
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule{}