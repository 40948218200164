import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { GetAllHomeMeasures, GetAllHomeMeasuresSuccess, GetNotificationSettings, GetNotificationSettingsSuccess, SetNotificationSettings, SetNotificationSettingsSuccess } from './homes.action';
import { HomesActions } from '@library/store/homes/homes.action';
import * as HomesVeluxSelector from './homes.selector';
import { NotificationSettings } from './homes.interface';
import { Share } from '@library/utils/interfaces/general-interfaces.interface';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { Home } from '@library/store/homes/homes.interface';
import { ConfigFacade } from '@library/store/config/config.facade';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class HomesFacadeVelux extends HomesFacade {


    constructor(
        protected store$: Store<SettingsState>,
        protected actions$: Actions<HomesActions>,
        private langPipe: LangPipe,
        private configFacade: ConfigFacade,
    ) {
        super(store$, actions$)
    }

    notificationSettings$ = this.store$.pipe(select(HomesVeluxSelector.getNotificationSettings));
    currentHomeGateway$ = this.store$.pipe(select(HomesVeluxSelector.getCurrentHomeGateway));
    currentHomeMeasures$ = this.store$.pipe(select(HomesVeluxSelector.getHomeMeasures));

    getNotificationSettings(home_id: string): Observable<Action> {
        this.store$.dispatch(GetNotificationSettings({ home_id }));

        return this.actions$.pipe(
            ofType(GetNotificationSettingsSuccess),
            take(1)
        );
    }



    setNotificationSettings(notification_settings: NotificationSettings, homeId: string): Observable<Action> {
        this.store$.dispatch(SetNotificationSettings({ notification_settings, homeId }));

        return this.actions$.pipe(
            ofType(SetNotificationSettingsSuccess),
            take(1)
        );
    }

    getAllHomeMeasures(gateway: string, module_id: string, measureType: string, home_id: string) {
        this.store$.dispatch(GetAllHomeMeasures({
            gateway, module_id, measureType, home_id
        }));

        return this.actions$.pipe(
            ofType(GetAllHomeMeasuresSuccess),
            take(1)
        );
    }

    getHomeInvitation(): Observable<Share> {
        return this.currentHomeId$.pipe(
            switchMap((homeId: Home['id']) => this.getAdminAccessCode(homeId)),
            withLatestFrom(this.currentHome$, this.adminAccessCode$),
            map(([, home, adminAccessCode]) => {

                const data: Share = {
                    title: this.langPipe.transform('common-app.__B2B_ADD_ADMIN_SUBJECT'),
                    url: `${environment.authUrl}/invitation/home/${adminAccessCode}/${home.id}/velux`,
                    text: this.langPipe.transform('common-app.__B2B_ADD_ADMIN_BODY', ['']).replace(/\\n/g, '\n')
                };
                return data;
            })
        );
    }
}
