import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditUserTimezoneComponent } from './edit-user-timezone.component';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { LayoutModule } from '@library/ui/layout/layout.module'
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { MoleculesModule } from '@library/ui/molecules/molecules.module'


@NgModule({
  declarations: [EditUserTimezoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    LayoutModule,
    AtomsModule,
    MoleculesModule,
  ],
  exports: [
    EditUserTimezoneComponent
  ]
})
export class EditUserTimezoneModule { }
