import { Injectable } from "@angular/core";
import { Const } from "@library/utils/constants/Const.constant";



@Injectable()
export class ClimateAutoSettingsService {

    constructor() {

    }

    calcHumidityRange(value: number) {
        let range;
        switch (value) {
            case 0:
                range = '10% - 90%';
                break;
            case 1:
                range = '15% - 80%';
                break;
            case 2:
                range = '20% - 70%';
                break;
            case 3:
                range = '25% - 60%';
                break;
            case 4:
            default:
                range = '30% - 50%';
                break;
        }
        return range;
    }


    calcCO2Value(value: number) {
        let ppm;
        switch (value) {
            case 0:
                ppm = '1600 ppm';
                break;
            case 1:
                ppm = '1400 ppm';
                break;
            case 2:
                ppm = '1150 ppm';
                break;
            case 3:
                ppm = '900 ppm';
                break;
            case 4:
            default:
                ppm = '700 ppm';
                break;
        }
        return ppm;
    }


    getOpeningDelayLabel(value: number) {
        if (value === 60) {
            return '1h'
        }
        return value + 'm'
    }


    convertCelcius(value: number) {
        return value / 10;
    }

    convertFarenheit(value: number) {
        return Math.round(((value / 10) * 1.8 + 32));
    }


    convertFromFarenheitToCelsius(value: number) {
        return Math.round((((value / 10) - 32) / 1.8))
    }

    convertToBackendValue(value: number, unit: number): number {
        if (this.isFarheneit(unit)) {
            const x = ((value - 32) / 1.8);
            return Math.round(x) * 10;
        } else {
            return value * 10;
        }
    }

    isFarheneit(unit: number){
        return unit === Const.NAPublicConst.UNIT_US
    }


    // get the current position of element by the left
    getLeftHandler(value: number, min: number, max: number) {
        return (value - min) * 100 / (max - min)
    }
}