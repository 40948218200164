import { AfterViewInit, Component, Inject, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BackOnAction } from '@library/utils/decorators/back-on-action.decorator';
import { NavigationCounter } from '@library/utils/services/navigation-counter.service';
import { UnitConverterService } from '@library/utils/services/unit-converter.service';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as L from 'mapbox.js';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { EnumHomesActions } from '@library/store/homes/homes.action';
import { HomesFacade, HOMES_FACADE } from '@library/store/homes/homes.facade';
import { Home, LatLng, PlaceUpdate } from '@library/store/homes/homes.interface';
import { getUser } from '@library/store/user/user.selector';
import { Loader } from '@library/utils/classes/loader';
import { Const } from '@library/utils/constants/Const.constant';
import { isSubmitable } from '@library/utils/helpers/forms/is-sub';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { MapboxService } from '@library/utils/services/mapbox.service';

@Component({
  selector: 'view-edit-user-location',
  templateUrl: './edit-user-location.component.html',
  styleUrls: ['./edit-user-location.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserLocationComponent implements OnInit, AfterViewInit {

  /**
   * Current home
   */
  @Input() home: Home;

  @Input() customMarker: string;

  /**
   * Subject and observable on the new coordinates selected by the user
   * Used to store these coordinates and compare them to the old ones to handle confirm button display
   * The subject is used to give a new value to the observable
   */
  newCoords = new Subject<LatLng>();
  newCoords$ = this.newCoords.asObservable();


  /**
   * Form from template
   * Used to get user input and change store accordingly
   */
  locationForm = new FormGroup({
    address: new FormControl('', Validators.required),
  });

  /**
   * View model of the view
   * Contains all of the data needed to perform functions in template
   * Only starts emitting once every observable has emitted once to prevent unloaded data error
   */
  homeLocationVM$ = combineLatest([
    this.facade.homeCoordinates$,
    this.newCoords$,
    this.facade.homeAltitude$,
    this.store.pipe(select(getUser))
  ]).pipe(
    map(([ coordinates, newCoords, homeAltitude, user ]) => {

      let altitude: string;

      if (user.unit_system === Const.NAPublicConst.UNIT_US) {
        altitude =  this.unitCoverter.convertMetersToFeet(homeAltitude) + ' ft';
      } else {
        altitude = homeAltitude + ' m';
      }
      return ({
        // Convert coordinates array to LatLng object
        homeAltitude: altitude,
        coordinates: new L.LatLng(coordinates[1], coordinates[0]) as LatLng,
        newCoords
      });
    }),
    delay(0)
  );

  /**
   * Observable used to determine whether the form is submitable or not
   * Checks if user input is different from store data
   */
  submitableForm$: Observable<boolean>;

  /**
   * Loader handling the spinning of the confirm button
   */
  confirmLoader: Loader;

  geocoding$ = this.locationForm.get('address').valueChanges.pipe(
    debounceTime(200),
    switchMap((addr) => {
      if (addr && addr !== '') {
        return this.mapBoxService.getPlacesByText(addr);
      } else {
        return of(null);
      }
    }),
  );

  autoCompletion = new Subject();
  autoCompletion$ = merge(
    this.autoCompletion.asObservable(),
    this.geocoding$
  );

  constructor(
    public injector: Injector,
    public actions: Actions,
    public navigationCounter: NavigationCounter,
    private mapBoxService: MapboxService,
    public store: Store<SettingsState>,
    @Inject(HOMES_FACADE) public facade: HomesFacade,
    public unitCoverter: UnitConverterService,
    public counter: NavigationCounter
  ) {
    this.confirmLoader =  new Loader(actions);
    this.facade.currentHome$.pipe(filter(h => !!h), take(1)).subscribe(home => {
      this.home = home;
    });
  }

  ngOnInit() {
    // Compare old and new coordinates to handle confirm button display
    this.submitableForm$ = this.newCoords$.pipe(
      isSubmitable(this.facade.homeCoordinates$.pipe(
        map(homeCoordinates => new L.LatLng(homeCoordinates[1], homeCoordinates[0]))
      ), this.locationForm)
    );
  }

  ngAfterViewInit() {
    // Set the new coordinates to the ones from the store
    if (this.home) {
      const coordinates = new L.LatLng(this.home.place.coordinates[1], this.home.place.coordinates[0]);
      this.newCoords.next(coordinates);
    } else {
      this.facade.currentHome$.pipe(filter(h => !!h), take(1)).subscribe(home => {
        const coordinates = new L.LatLng(home.place.coordinates[1], home.place.coordinates[0]);
        this.newCoords.next(coordinates);
      });
    }
  }

  /**
   * Gets the selected position and closes the search results dropdown
   * @param coordinates Coordinates of the selected location
   */
  selectPosition(coordinates: LatLng): void {
    this.positionChange(coordinates);
    this.closeDropdown();
  }

  /**
   * Sets the new coordinates to the selected ones and updates the address name in the search bar
   * @param coordinates Coordinates of the selected location
   */
  positionChange(coordinates: LatLng): void {
    // Set the new coordinates
    this.newCoords.next(coordinates);

    // Update the address name
    this.mapBoxService.getAddressByCoordinates(coordinates).pipe(
      tap((address) => {
        this.locationForm.patchValue({ address }, { emitEvent: false})
      }),
      take(1)
    ).subscribe();
  }

  /**
   * Closes the search result dropdown
   */
  closeDropdown() {
    this.autoCompletion.next(null);
  }

  /**
   * Interchanges the latitude and longitude of a LatLng object
   * @param coordinates Coordinates we want to reverse
   * @returns LatLng object with interchanged coordinates
   */
  interchangeCoordinates(coordinates: LatLng): LatLng {
    return new L.LatLng(coordinates.lng, coordinates.lat) as LatLng;
  }

  /**
   * Updates the home location and sets the home timezone accordingly
   * @param coordinates New coordinates
   */
  @BackOnAction(
    EnumHomesActions.UpdateHomePlaceSuccess,
    EnumHomesActions.UpdateHomePlaceFailure,
  )
  updateHomeLocation(coordinates: LatLng): void {

    const placeUpdate: PlaceUpdate = {
      home_id: this.home.id,
      lat: coordinates.lat,
      lon: coordinates.lng,
      safe: true
    };

    this.facade.updatePlace(placeUpdate);
    this.confirmLoader.loadUntil([EnumHomesActions.UpdateHomePlaceSuccess, EnumHomesActions.UpdateHomePlaceFailure]);
    this.closeDropdown();
  }

}
