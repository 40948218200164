export interface Scenario {
  id: string,
  type: string,
  customizable: boolean,
  name?: string
  homeId: string,
  modulesId?: string[],
}

export interface ModuleScenario {
  id: string,
  bridge: string,
  scenarios: Array< {id: string, target_position?: number}>
}

export interface HomeScenario {
  id: string,
  scenarios: Scenarios,
  modules?: ModuleScenario[]
}

export enum DefaultScenario {
  WAKE_UP = 'wake_up',
  BEDTIME = 'bedtime',
  HOME = 'home',
  AWAY = 'away'
}

export const scenarioIcons = new Map([
  [DefaultScenario.WAKE_UP, 'wake-up'],
  [DefaultScenario.BEDTIME, 'sleep'],
  [DefaultScenario.HOME, 'at-home-32'],
  [DefaultScenario.AWAY, 'away-32']
]);

export type Scenarios = Scenario[];


