import { Enums } from "src/app/constants/App.constant";
import { ScheduleCard, ScheduleRoom, ScheduleVelux } from "./schedules.interface";


export interface SchedulesStateVelux {
  schedules: ScheduleRoom;
  loading: boolean;
  currentScheduleId: string;
  currentScheduleCard: ScheduleCard;
  scheduleToAdd: ScheduleCard;
  errorModalDisplay: boolean;
}

export const initialSchedulesStateVelux: SchedulesStateVelux = {
  schedules: null,
  loading: false,
  currentScheduleId: null,
  currentScheduleCard: null,
  scheduleToAdd: {
    begin_time_ref: Enums.timings.HOURLY,
    begin_time: 540,
    end_time_ref: Enums.timings.HOURLY,
    end_time: 1320,
    modules: null
  },
  errorModalDisplay: false,
};
