import { Directive, Input, Output, EventEmitter, SimpleChange, OnInit, OnChanges } from '@angular/core';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { MapboxService } from '@library/utils/services/mapbox.service';
import * as L from 'mapbox.js';
import { LatLng } from '@library/store/homes/homes.interface';

@Directive({
  selector: '[map]',
})
export class MapDirective implements OnInit, OnChanges {

  private map: L.mapbox.map;
  private marker: L.marker;
  private geolocate;

  @Input() coordinates;
  @Input() draggableMarker = true;
  @Input() zoom = 18;
  @Input() markerIcon: string;
  @Output() changePosition = new EventEmitter<L.LatLng>();

  constructor(
    private langPipe: LangPipe,
    private mapBoxService: MapboxService,
  ) { }

  ngOnInit() {
    /**
     * Create map
     */
    this.map = this.mapBoxService.createMap('map', null, {
      center: this.coordinates,
      zoom: this.zoom
    }).addLayer(L.mapbox.styleLayer('mapbox://styles/netatmo/cipfj59jj0014dknhudapxm69'));

    /**
     * Add marker to map
     */
    if (this.markerIcon) {
      this.marker = this.mapBoxService.createCustomMarker(this.markerIcon, this.coordinates, this.draggableMarker);
    } else {
      this.marker = this.mapBoxService.createMarker(this.coordinates, {draggable: this.draggableMarker});
    }
    this.marker.addTo(this.map);

    /**
     * Add geolocate to map
     */
    this.geolocate = document.getElementById('geolocate');
    if (this.geolocate) {
      if (!navigator.geolocation) {
        this.geolocate.innerHTML = this.langPipe.transform('common-settings.__NO_GEOLOCATION');
      } else {
        if (this.geolocate) {
          this.geolocate.onclick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.map.locate();
          };
        }
      }
    }

    /**
     * Update new position
     */
    this.map.on('locationfound', (e) => {
      this.moveToCoordinates(e.latlng, e.bounds);
      this.propagateChange(e.latlng);
    });

    this.marker.on('dragend', (e) => {
      this.moveToCoordinates(this.marker.getLatLng());
      this.propagateChange(this.marker.getLatLng());
    });
  }

  ngOnChanges(sc: { coordinates: SimpleChange }) {
    if (!this.map) {
      return;
    }
    this.moveToCoordinates(sc.coordinates.currentValue);
  }

  /**
   * Propagates a "change position" event when the position changes
   * @param coordinates Coordinates of the new position
   */
  propagateChange(coordinates: LatLng): void {
    this.changePosition.emit(coordinates);
  }

  /**
   * Sets the map boundaries and the marker position
   * @param coordinates Coordinates of the new position
   * @param bounds Boundaries of the map
   */
  moveToCoordinates(coordinates: L.LatLng, bounds = null): void {
    this.map.panTo(coordinates);
    if (bounds) {
      this.map.fitBounds(bounds);
    }

    this.setPosition(coordinates);
  }

  /**
   * Sets the coordinates of the marker
   * @param coordinates Coordinates of the new position
   */
  setPosition(coordinates) {
    this.marker.setLatLng(coordinates);
  }
}
