import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

// Requires NPM install of fast-deep-equal. Build will fail if not installed.
// https://github.com/jvandemo/generator-angular2-library/issues/221
import * as isEqual_ from 'fast-deep-equal';
const isEqual = isEqual_;



/**
 * Validates whether a form is submitable or not. Checks for form value differenes, and form validity based on form requirements
 * @param source represents store to be observed as part of form
 * @param form represents FormGroup object created in project to utilize angular form functions
 */

export function isSubmitable<U>(source: Observable<U>, form: FormGroup) {
    return <T>(changes: Observable<T>): Observable<boolean> => {

      const isSubmitable$ = combineLatest([
        source,
        changes
      ]).pipe(
        debounceTime(200),
        map(([a, b]) => !isEqual(a, b) && form.valid),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

      return isSubmitable$;
    };
}

/**
 * Used in Settings Lux
 * Used in Settings Legrand
 * Used in Settings Energy
 */
