import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { GetScenarios, GetScenariosFailure, GetScenariosSuccess, SetScenarios, SetScenariosFailure, SetScenariosSuccess } from './scenarios.actions'
import { ScenariosService } from './scenarios.service'

@Injectable()
export class ScenariosEffects {

  loadHomeScenarios$ = createEffect(() => this.actions$.pipe(
    ofType(GetScenarios),
    exhaustMap((action) => this.scenariosService.loadScenarios(action.homeId)
      .pipe(
        map(payload => GetScenariosSuccess(payload)),
        catchError((error) => of(GetScenariosFailure(error)))
      )
    ))
  );

  setScenarios$ = createEffect(() => this.actions$.pipe(
    ofType(SetScenarios),
    exhaustMap((action) => this.scenariosService.setScenarios(action.params)
      .pipe(
        map(() => SetScenariosSuccess({home: action.params.home})),
        catchError((error) => of(SetScenariosFailure(error)))
      )
    ))
  );

  constructor(
    private actions$: Actions,
    private scenariosService: ScenariosService
  ) {}
}
