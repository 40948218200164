import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CallbackService } from '@library/utils/services/callback.service';
import { UserAgentService } from '@library/utils/services/user-agent.service';

@Injectable()
export class BlockBackButtonGuard implements CanActivate {
  constructor(
    private router: Router,
    private userAgent: UserAgentService,
    private callback: CallbackService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const previousUrl = this.router.getCurrentNavigation().previousNavigation?.finalUrl?.toString();
    if (typeof previousUrl !== "undefined" && previousUrl.startsWith('/automations/')) {
      if (this.userAgent.isNativeApp()) {
        this.callback.call(this.callback.list.shouldExit);
      } else {
        window.history.go(-3)
      }
      return false;
    }
    return true;
  }
}
