import { createSelector } from '@ngrx/store';
import { getCurrentHomeId } from '@library/store/homes/homes.selector';
import { AppState } from '../app.state';

const homesState = (state: AppState) => state.homes.homes;
const modulesState = (state: AppState) => state.modules.all;


export const getNotificationSettings = createSelector(
  getCurrentHomeId,
  homesState,
  (currentId, homes) => {
    const home = homes.find(h => h.id === currentId);
    if (!home) { return null; }
    return (home as any).notification_settings
  }
);


export const getCurrentHomeGateway = createSelector(
  getCurrentHomeId,
  modulesState,
  (currentId, modules) => {
    const gateway = modules.find(m => m.homeId === currentId && m.type === 'NXG');
    return gateway
  }
);

export const getHomeMeasures = createSelector(
  getCurrentHomeId,
  homesState,
  (currentId, homes) => {
    return homes.find(home => home.id === currentId).measures
  }
);