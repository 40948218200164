import { createAction, props } from '@ngrx/store';
import { HomeScenario, ModuleScenario } from './scenarios.interface'

export const GetScenarios = createAction(
  '[Scenarios] Get Scenarios',
  props<{ homeId: string, deviceTypes?: string[], filter?: string[] }>()
);

export const GetScenariosSuccess = createAction(
  '[Scenarios] Get Scenarios success',
  props<{ home: HomeScenario }>()
);


export const GetScenariosFailure = createAction(
  '[Scenarios] Get Scenarios failure',
  props<{ payload: any }>()
);



export const SetScenarios = createAction(
  '[Scenarios] Set Scenarios',
  props<{ params: any }>()
);

export const SetScenariosSuccess = createAction(
  '[Scenarios] Set Scenarios success',
  props<{ home: any }>()
);


export const SetScenariosFailure = createAction(
  '[Scenarios] Set Scenarios failure',
  props<{ payload: any }>()
);
