import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { PageLoaderFacade } from '@library/store/page-loader/page-loader.facade';
import { Analytics } from '@library/utils/services/analytics.service';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { delay, firstValueFrom, Subscription } from 'rxjs';
import { PageLoaderFacadeVelux } from './stores/page-loader/page-loader.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {

  isPageLoading$ = this.pageLoaderFacade.isPageLoading$.pipe(
    delay(0)
  );

  constructor(
    private userAgent: UserAgentService,
    private pageLoaderFacade: PageLoaderFacade,
    private homesFacade: HomesFacade,
    private analytics: Analytics,
  ) {
    // Init user consent for analytics
    this.analytics.initUserConsent();
  }

  async ngOnInit() {
    this.setDeviceClasses();
    const params = {
      gateway_types: ['NXG'],
    };
    await firstValueFrom(this.homesFacade.loadHomes(params));
  }

  /**
   * For Iphone X: add viewport-fit=cover to prevent the confirm button from being cut
   */
  ngAfterViewChecked() {
    const viewport = document.head.getElementsByTagName('meta').namedItem('viewport');

    // If the viewport meta tag doesn't contain "viewport-fit=cover", add it
    if (viewport.content.indexOf('viewport-fit=cover') === -1) {
      viewport.content += ', viewport-fit=cover';
    }
  }

  /**
   * Add device environement classes to body
   * depends on user agent
   */
  setDeviceClasses() {
    if (this.userAgent.isNativeApp()) {
      document.body.classList.add('is-native');
    }

    if (this.userAgent.isMobile()) {
      document.body.classList.add('is-mobile');
    } else {
      document.body.classList.add('is-desktop');
    }

    if (this.userAgent.isIos()) {
      document.body.classList.add('is-ios');
      document.body.classList.add(
        `is-ios-version-${this.userAgent.iosVersion().trim()}`
      );
    }

    if (this.userAgent.isAndroid()) {
      document.body.classList.add('is-android');
    }
  }

}
