import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserReducers } from './user.reducer';
import { UserEffects } from './user.effect';
import { UserFacade } from './user.facade';
import { UserService } from './user.service';

@NgModule({
  imports: [
    StoreModule.forFeature('user', UserReducers),
    EffectsModule.forFeature([
      UserEffects,
    ]),
  ],
  providers: [
    UserService,
    UserFacade
  ]
})
export class UserModule {}
