import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { DefaultScenario } from './scenarios.interface'

const scenariosState = (state: SettingsState) => state.scenarios;

export const getScenarios = createSelector(
  scenariosState,
  state => state.all
);


export const getModuleScenarios = createSelector(
  scenariosState,
  state => {
    if(!state.module){
      return null
    }
    return state.module
  }
);

export const defaultScenarios = createSelector(
  getScenarios,
  scenarios =>
    scenarios.filter(scenario =>
      (<any>Object).values(DefaultScenario).includes(scenario.id)
    )
)

export const customScenarios = createSelector(
  getScenarios,
  scenarios => scenarios.filter(scenario =>
    !(<any>Object).values(DefaultScenario).includes(scenario.id)
  )
)
