import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Home } from "@library/store/homes/homes.interface";
import { User } from "@library/store/user/user.interface";
import { HAS_SHARED } from "@library/ui/directives/share/share.directive";
import { LangPipe } from "@library/utils/pipes/lang.pipe";
import { CallbackService } from "@library/utils/services/callback.service";
import { HeaderService } from "@library/utils/services/header.service";
import { firstValueFrom, BehaviorSubject, map, combineLatest, Observable, filter } from "rxjs";
import { HomesFacadeVelux } from "src/app/stores/homes/homes.facade";


@Component({
    selector: 'app-users-view',
    templateUrl: './users-view.component.html',
    styleUrls: ['./users-view.component.scss'],
})
export class UsersViewComponent implements OnInit {


    // Observable to determine if data has been shared via copy
    copied = new BehaviorSubject(false);
    copied$ = this.copied.asObservable();

    /**
   * User selected when clicking on the remove icon
   * Used to pass selected user info to the confirm modal in the view
   */
    selectedUser = new BehaviorSubject<User | null>(null);
    selectedUser$ = this.selectedUser.asObservable();

    /**
       * Loader handling the spinning of the "Revoke access" button
       */
    isLoading$ = new BehaviorSubject<boolean>(false);

    /**
   * Boolean handling the display of remove user confirm modal
   * Shows when clicking on the "remove user" icon
   * Asks the user if he is sure about removing a guest from his home
   */
    removeUserModalDisplay$ = new BehaviorSubject(false);

    currentHomeId$: Observable<Home['id']> = this.homesFacade.currentHomeId$;
    homeUsers$: Observable<User[]> = this.homesFacade.homeUsers$;

    // Observable that emits home invitation
    homeInvite$ = this.homesFacade.getHomeInvitation();

    /**
   * View model of the users view
   * Contains all of the data needed to perform functions in template
   * Only starts emitting once every observable has emitted once to prevent unloaded data error
   */
    viewModel$ = combineLatest({
        homeUsers: this.homeUsers$,
        selectedUser: this.selectedUser$,
        hasCopied: this.copied$,
    })

    constructor(
        private homesFacade: HomesFacadeVelux,
        private langPipe: LangPipe,
        private headerService: HeaderService,
        private callback: CallbackService,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.headerService.push({ title: this.langPipe.transform('common-settings.__GUEST_MANAGEMENT') });
        this.titleService.setTitle('Guest management');
    }

    removeUser(user: User): void {
        this.selectedUser.next(user);
        this.removeUserModalDisplay$.next(true);
    }

    /**
   * Removes a user's access to the home
   * @param homeId ID of the home
   */
    async removeUserFromHome() {
        this.isLoading$.next(true);
        const homeId = await firstValueFrom(this.homesFacade.currentHomeId$);
        const user = await firstValueFrom(this.selectedUser$);
        if (user) {
            await firstValueFrom(this.homesFacade.removeUserFromHome(homeId, String(user.id)))
            this.removeUserModalDisplay$.next(false);
            if (user.is_caller) {
                this.callback.call(this.callback.list.didUpdateUserData, { updated: 'dissociateMyAccess' });
                this.callback.call(this.callback.list.shouldExit);
            } else {
                this.callback.call(this.callback.list.didUpdateUserData, { updated: 'dissociateOtherAccess' });
            }
            this.isLoading$.next(false);
        }
    }

    /**
   * Sets text displayed in the remove user modal
   * The text according to whether the user we are trying to remove is a guest or the home owner
   * @param user User we want to remove
   */
    setConfirmText(user: User) {
        return user?.is_caller ?
            this.langPipe.transform('common-settings.__B2B_DISSOCIATE_MYSELF_BOX') :
            this.langPipe.transform('common-settings.__B2B_DISSOCIATE_OTHERS_BOX', [user?.name]);
    }


    hasCopied(event): void {
        if (event === HAS_SHARED.fallback) {
            this.copied.next(true);
        } else {
            this.copied.next(false);
        }
    }

    protected rawurlencode(str) {
        str = (str + '\n').toString();
        return encodeURIComponent(str)
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/\*/g, '%2A');
    }

}