import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DirectivesModule } from "@library/ui/directives/directives.module";
import { UIModule } from "@library/ui/ui.module";
import { PipesModule } from "@library/utils/pipes/pipes.module";
import { UsersPageComponent } from "./users-page.component";
import { UsersViewComponent } from "./users-view/users-view.component";


const routes: Routes = [
    {
        path: '',
        component: UsersPageComponent,
        children: [
            {
                path: '',
                component: UsersViewComponent
            }
        ]
    }
]

@NgModule({
    declarations: [
        UsersPageComponent,
        UsersViewComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        PipesModule,
        DirectivesModule,
        UIModule,
    ]
})
export class UsersPageModule{}