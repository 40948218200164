import { ofType, Actions } from '@ngrx/effects';
import { first, tap } from 'rxjs/operators';
import { NavigationCounter } from '../services/navigation-counter.service';

export function BackOnAction(successAction: string | string[], failureAction: string | string[], numBack = -1) {

  return (target, propertyKey, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function() {
      const actions: Actions = this.actions;
      // Important when importing to name your navigation counter using the below name
      const navigationCounter: NavigationCounter = this.navigationCounter;

      if (!Array.isArray(successAction)) {
        successAction = [successAction];
      }

      if (!Array.isArray(failureAction)) {
        failureAction = [failureAction];
      }

      actions.pipe(
        ofType(...successAction, ...failureAction),
        first(),
        tap((action) => {

          if (failureAction.includes(action.type)) {
            return;
          }

          // When adding this feature, most views do not implement Navigation counter
          // This is to prevent errors, but apps shoul place NavigationCounter in one way or another
          // This feature should be used especially if the app using this function has a non-native implementation
          // Ex: Settings Lux, Settings Weather, Settings Energy, Settings Legrand
          if (navigationCounter) {
            // If we are on the first page of a site, do not go back
            if (navigationCounter.nbHistoryEntries === 1) {
              return;
            }

          }

          window.history.go(numBack);
        }),
      ).subscribe();

      originalMethod.apply(this, arguments);
    };

    return descriptor;
  };
}
