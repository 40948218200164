export namespace supportedLangs {

    export const SupportedLanguage = {
        veluxMulti: {
            'cs-CZ': 'cze-CZE',
            'da-DK': 'da-DK',
            'de-AT': 'de-AUT',
            'de-CH': 'de-CHE',
            'de-DE': 'de-DE',
            'en-GB': 'en-GB',
            'en-US': 'en-GB',
            'es-ES': 'es-ES',
            'fi-FI': 'fin-FIN',
            'fr-BE': 'fr-BEL',
            'fr-CA': 'fr-CAN',
            'fr-CH': 'fr-CHE',
            'fr-FR': 'fr-FR',
            'hr-HR': 'hr-HR',
            'it-CH': 'it-CHE',
            'it-IT': 'it-IT',
            'nb-NO': 'nb-NO',
            'nl-BE': 'nl-BEL',
            'nl-NL': 'nl-NL',
            'pl-PL': 'pl-PL',
            'pt-PT': 'pt-PT',
            'sk-SK': 'sk-SK',
            'sl-SI': 'sl-SI',
            'sp-US': 'sp-US',
            'sv-SE': 'sv-SE',
            'tr-TR': 'tr-TR',
            'sr-RS': 'sr-RS',
            'ro-RO': 'ro-RO',
            'hu-HU': 'hu-HU',
            'bg-BG': 'bg-BG',
            'ja-JP': 'jp-JP'
        },
        multi: {
            'cs-CZ': 'cs-CZ',
            'da-DK': 'da-DK',
            'de-AT': 'de-AT',
            'de-CH': 'de-CH',
            'de-DE': 'de-DE',
            'en-GB': 'en-GB',
            'en-US': 'en-GB',
            'es-ES': 'es-ES',
            'fi-FI': 'fi-FI',
            'fr-BE': 'fr-BE',
            'fr-CH': 'fr-CH',
            'fr-FR': 'fr-FR',
            'hr-HR': 'hr-HR',
            'it-IT': 'it-IT',
            'nb-NO': 'nb-NO',
            'nl-BE': 'nl-BE',
            'nl-NL': 'nl-NL',
            'pl-PL': 'pl-PL',
            'pt-PT': 'pt-PT',
            'sk-SK': 'sk-SK',
            'sl-SI': 'sl-SI',
            'sv-SE': 'sv-SE',
            'tr-TR': 'tr-TR',
            'sr-RS': 'sr-RS',
            'ro-RO': 'ro-RO',
            'hu-HU': 'hu-HU',
            'bg-BG': 'bg-BG',
            'ja-JP': 'ja-JP'
        },
        single: {
            'en-US': 'en-GB',
            'en-GB': 'en-GB',
            'fr-FR': 'fr',
            'de-DE': 'de',
            'es-ES': 'es-ES',
            'it-IT': 'it',
            'nl-NL': 'nl',
            'zh-CN': 'zh-CN',
            'zh-TW': 'zh-TW',
            'ja-JP': 'ja',
            'ru-RU': 'ru',
            'nb-NO': 'no',
            'sv-SE': 'sv-SE',
            'da-DK': 'da',
            'cs-CZ': 'cs',
            'pl-PL': 'pl',
            'de-AT': 'de',
            'de-CH': 'de',
            'fi-FI': 'fi',
            'fr-BE': 'fr',
            'fr-CA': 'fr',
            'fr-CH': 'fr',
            'hr-HR': 'hr',
            'it-CH': 'it',
            'nl-BE': 'nl',
            'pt-PT': 'pt-PT',
            'sk-SK': 'sk',
            'sl-SI': 'sl',
            'sp-US': 'sp-US',
            'tr-TR': 'tr',
            'sr-RS': 'sr',
            'ro-RO': 'ro',
            'hu-HU': 'hu',
            'bg-BG': 'bg',
        }
    };


    export const BackupLanguage = {
        multi: {
            'bs': 'bs-BA',
            'ca': 'ca-CA',
            'cs': 'cze-CZE',
            'da': 'da-DK',
            'de': 'de-DE',
            'el': 'el-GR',
            'en': 'en-GB',
            'es': 'es-ES',
            'fr': 'fr-FR',
            'hr': 'hr-HR',
            'hu': 'hu-HU',
            'it': 'it-IT',
            'ja': 'jp-JP',
            'ko': 'ko-KO',
            'nb': 'nb-NO',
            'nl': 'nl-NL',
            'pl': 'pl-PL',
            'pt': 'pt-PT',
            'ro': 'ro-RO',
            'ru': 'ru-RU',
            'sk': 'sk-SK',
            'sl': 'sl-SI',
            'sr': 'sr-RS',
            'sv': 'sv-SE',
            'tr': 'tr-TR',
            'uk': 'uk-UA',
            'zh': 'zh-CN',
            'fi': 'fin-FIN',
            'bg': 'bg-BG',
            'sp': 'en-US'
        },
        single: {
            'en': 'en-GB',
            'fr': 'fr',
            'de': 'de',
            'es': 'es-ES',
            'it': 'it',
            'nl': 'nl',
            'zh': 'zh-CN',
            'ja': 'ja',
            'ru': 'ru',
            'nb': 'no',
            'sv': 'sv-SE',
            'da': 'da',
            'cs': 'cs',
            'pl': 'pl',
            'bs': 'bs',
            'ca': 'ca',
            'el': 'el',
            'hr': 'hr',
            'hu': 'hu',
            'ko': 'ko',
            'pt': 'pt-PT',
            'ro': 'ro',
            'sk': 'sk',
            'sl': 'sl',
            'sr': 'sr',
            'tr': 'tr',
            'uk': 'uk',
            'fi': 'fin',
            'bg': 'bg'
        }
    };

    export const DefaultLanguage = {
        multi: 'en-GB',
        single: 'en-GB'
    }
};

