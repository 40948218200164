import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HomesFacade } from "@library/store/homes/homes.facade";
import { PageLoaderFacade } from "@library/store/page-loader/page-loader.facade";
import { isDefined } from "@library/utils/helpers/store/is-defined";
import { firstValueFrom, share } from "rxjs";


@Component({
    selector: 'app-users-page',
    templateUrl: './users-page.component.html',
    styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent implements OnInit{

     
    constructor(
        private homesFacade: HomesFacade,
        private activatedRoute: ActivatedRoute,
        private pageLoaderFacade: PageLoaderFacade
    ) {}

    async ngOnInit() {
        const homeId = this.activatedRoute.snapshot.params.home_id;
        this.homesFacade.selectHome(homeId);
        await firstValueFrom(this.homesFacade.currentHome$.pipe(isDefined()))
        await firstValueFrom(this.homesFacade.loadHomeUsers(homeId));
        this.pageLoaderFacade.handlePageLoader()
    }
    
}