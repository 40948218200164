import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RoomsReducers } from './rooms.reducer';
import { RoomsEffects } from './rooms.effect';
import { RoomsFacade } from './rooms.facade';
import { RoomsService } from './rooms.service';

@NgModule({
  imports: [
    StoreModule.forFeature('rooms', RoomsReducers),
    EffectsModule.forFeature([
      RoomsEffects,
    ]),
  ],
  providers: [
    RoomsService,
    RoomsFacade
  ]
})
export class RoomsModule {}
