import { Injectable } from '@angular/core';
import { Home } from '@library/store/homes/homes.interface';
import { User } from '@library/store/user/user.interface';
import { Const } from '@library/utils/constants/Const.constant';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enums } from 'src/app/constants/App.constant';
import { HomesServiceVelux } from 'src/app/stores/homes/homes.service';
import { RoomVelux } from 'src/app/stores/rooms/rooms.interface';
import { ScheduleCard } from 'src/app/stores/schedules/schedules.interface';

@Injectable()
export class AirQualityService {


    private readonly periodChange$ = new Subject<string>();
    private readonly isLoading$ = new Subject<boolean>();

    constructor(
        private homeService: HomesServiceVelux,
        private lang: LangPipe
    ) {
    }

    setPeriod(text: string): void {
        this.periodChange$.next(text);
    }

    periodChanges(): Observable<string> {
        return this.periodChange$.asObservable();
    }

    setLoading(load: boolean): void {
        this.isLoading$.next(load);
    }

    isLoading(): Observable<boolean> {
        return this.isLoading$.asObservable();
    }

    // Get the numbers of values which should normally have depending on the period
    getTotalData(period: string) {
        switch (period) {
            case '1day':
                return 2 * 24 // Temporary while scale is 30 minutes. Should return to 12*24 when scale returns to 5min
                break;
            case '1week':
                return 2 * 24 * 7
                break;
            case '1month':
                return 24 * 30
                break
            default:
                return 1;
        }
    }

    getAllMeasures(params: { [key: string]: any }) {

        const end = Math.floor(Date.now() / 1000);
        const beginDay = end - 86400;
        const beginWeek = end - 604800;
        const beginMonth = end - 2592000;

        const homePayloadPartition = {
            home: {
                id: params['home_id'],
                modules: [
                    {
                        id: params['module_id'],
                        bridge: params['gateway'],
                        type: params['measureType']
                    }
                ]
            }
        };

        const paramsOneDay = {
            scale: '30min', // Temp switching to 30 min until new backend solution is ready
            date_begin: beginDay.toString(),
            date_end: end.toString(),
            ...homePayloadPartition
        };

        const paramsOneWeek = {
            scale: '30min',
            date_begin: beginWeek.toString(),
            date_end: end.toString(),
            device_id: params['gateway'],
            ...homePayloadPartition
        };


        const paramsOneMonth = {
            scale: '1hour',
            date_begin: beginMonth.toString(),
            date_end: end.toString(),
            device_id: params['gateway'],
            ...homePayloadPartition
        };


        return forkJoin(
            this.homeService.homeMeasures(paramsOneDay),
            this.homeService.homeMeasures(paramsOneWeek),
            this.homeService.homeMeasures(paramsOneMonth),
        ).pipe(
            map((values) => (values))
        );
    }


    // Get temperature depending on the unit ( Farheneit or Celsius )
    getTemperature(user: User, value: number) {
        if (user.unit_system === Const.NAPublicConst.UNIT_US) {
            return Math.round(((value / 10) * 1.8 + 32));
        }
        return value / 10
    }


    /*
    get the state of the current temperature
    - Comfortable if >= Tmin and <= Tmax
    - Cold if < Tmin
    - Hot if > Tmax
    */
    getTemperatureState(user: User, room: RoomVelux) {
        if (this.getTemperature(user, room.temperature) >= this.getTemperature(user, room.min_comfort_temperature) &&
            this.getTemperature(user, room.temperature) <= this.getTemperature(user, room.max_comfort_temperature)) {

            return {
                label: this.lang.transform("app.__IAQ_TEMP_COMFORTABLE"),
                color: "#2dd160"
            }
        } else if (this.getTemperature(user, room.temperature) < this.getTemperature(user, room.min_comfort_temperature)) {
            return {
                label: this.lang.transform("app.__IAQ_TEMP_COLD"),
                color: "#3869ff"
            }
        }
        else {
            return {
                label: this.lang.transform("app.__IAQ_TEMP_HOT"),
                color: "#ff4038"
            }
        }
    }



    /*
        get the state of the current humidity
        - Healthy if >= 20% and <= 70%
        - Dry if < 20
        - Humid if > 70
    */
    getHumidityState(room: RoomVelux) {
        if (room.humidity <= 70 && room.humidity >= 20) {
            return {
                label: this.lang.transform("app.__IAQ_RH_HEALTHY"),
                color: "#2dd160"
            }
        } else if (room.humidity < 20) {
            return {
                label: this.lang.transform("app.__IAQ_RH_DRY"),
                color: "#fdc608"
            }
        }
        else {
            return {
                label: this.lang.transform("app.__IAQ_RH_HUMID"),
                color: "#3869ff"
            }
        }
    }



    /*
        get the state of the current co2
        - Healthy if < 800 ppm
        - High if < 1200 ppm and >= 800 ppm
        - Very high if >= 1200
    */
    getCo2State(room: RoomVelux) {
        if (room.co2 < 1200 && room.co2 >= 800) {
            return {
                label: this.lang.transform("app.__IAQ_CO2_HIGH"),
                color: "#fdc608"
            }
        } else if (room.co2 < 800) {
            return {
                label: this.lang.transform("app.__IAQ_CO2_HEALTHY"),
                color: "#2dd160"
            }
        }
        else {
            return {
                label: this.lang.transform("app.__IAQ_CO2_VERY_HIGH"),
                color: "#ff4038"
            }
        }
    }


    // Determine if the home is located in the north hemisphere
    // The latitude should be bigger than 0
    isHemisphereNorth(home: Home) {
        return home.place.coordinates[1] > 0
    }

    // Determine if the home is located in the south hemisphere
    // The latitude should be smaller or equal to 0
    isHemisphereSouth(home: Home) {
        return home.place.coordinates[1] <= 0

    }


    /*
        To determine if we are in summer season
    */
    isSummerSeason(home: Home) {
        // We get number of the current Month
        /*
            January => 0
            ... December => 11
            This is why we compute by 1
        */
        const currentMonth = (new Date()).getMonth() + 1

        // In the northern hemisphere the summer is from May => 5 to October => 10
        if (this.isHemisphereNorth(home) && currentMonth >= 5 && currentMonth <= 10) {
            return true
        }

        // In the southern hemisphere the summer is from November => 11 to April => 4
        else if (this.isHemisphereSouth(home) && (currentMonth >= 11 || (currentMonth >= 1 && currentMonth <= 4))) {
            return true
        }

        else {
            return false
        }
    }

    /*
       To determine if we are in winter season
   */
    isWinterSeason(home: Home) {

        // We get number of the current Month
        /*
            January => 0
            ... December => 11
            This is why we compute by 1
        */
        const currentMonth = (new Date()).getMonth() + 1

        // In the northern hemisphere the winter is from November => 11 to April => 4
        if (this.isHemisphereNorth(home) && (currentMonth >= 11 || (currentMonth >= 1 && currentMonth <= 4))) {
            return true
        }

        // In the southern hemisphere the winter is from May => 5 to October => 10
        else if (this.isHemisphereSouth(home) && currentMonth >= 5 && currentMonth <= 10) {
            return true
        }

        else {
            return false
        }
    }

    /*
        Return true if We spend at least 6 hours above valueAbove number during the last 7 days
        ** measuresArray => the array of the last 7 days measures
    */
    isAboveAtLeast6hPast7Days(measuresArray: number[], valueAbove: any) {

        let sum = 0
        /*
            For the 7 days we get the measure for each 30 minutes
            So we calculate the sum of measure above valueAbove
        */
        measuresArray.forEach(measure => {
            if (measure !== null && measure > valueAbove) {
                sum++
            }
        })

        // Convert the total in hours rounded to the nearest multiple of 0.5
        let hours = Math.round(((sum * 30) / 60) * 2) / 2

        if (hours >= 6) {
            return true
        }
        return false
    }

    // Determine if an automationType is defined among the automation
    automationExist(automationName: string, automationList) {
        for (let card of automationList) {
            if (card.card_enabled && typeof card.algos !== "undefined" && card.algos.includes(automationName)) {
                return true
            }
        }

        return false
    }


    /*
        Determine if the total automation time is less than the time between 10 am and 10 pm through the week
        From 10 am to 10 pm => 12 hours
        Though the week => 12*7 = 84
        So we should determine if the total automation time is less than 50%*84 => 42 hours
    */
    automationTimeIsLessThan50percent(automationTime: number) {

        //Numbers of hours
        // From 10am to 10pm for 7 days => 12*7 = 84 hours

        const percentage = (automationTime / 84.0) * 100

        if (percentage < 50) {
            return true
        }

        return false
    }

    /**
     * Check to see if cards include given automation, and if automation is set to run more than 1 day a week
     * This function assumes you already know automation exists amongst Schedule Cards
     */
    automationShouldRunMore(cards: ScheduleCard[], automation: string) {
        /**
         * Iterate over cards to check if automation is included
         * If it is, check to see how long it runs
         * If it runs more days than just 1, ignore card
         * If it runs 1 day, return true
         */
        for (const card of cards) {
            if (card.algos?.includes(automation) && card.days.length <= 1) {
                return true;
            }
        }

        return false;

    }

    /*
        return true if the maximum window opening is below 75%
    */
    windowBelowThreshold(room: RoomVelux) {
        return room.config.algo_max_window_opening < 75;
    }


    waitingTimeMoreThanOneHour(waitingTime: number) {
        return (waitingTime / 60) > 1
    }




    getTotalAutomationTime(automationName: string, automationList: any[]) {

        let total = 0
        for (let card of Array.from(automationList)) {
            if (card.card_enabled && typeof card.algos !== "undefined" && card.algos.includes(automationName)) {
                const nbDays = card.days.length
                const sum = (this.getHours(card.end_time_ref, card.end_time) - this.getHours(card.begin_time_ref, card.begin_time)) * nbDays
                total += (sum) / 60
            }
        }
        return total

    }

    getHours(time_ref: string, time: number) {

        if (time_ref === "sunset") {
            return 1320
        } else if (time_ref === "sunrise") {
            return 600
        } else if (time_ref === "hourly") {
            if (time < 600) {
                return 600
            } else if (time > 1320) {
                return 1320
            } else {
                return time
            }
        }
    }

}
