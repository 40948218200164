import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UIModule } from "@library/ui/ui.module";
import { EditUserLocationModule } from "@library/ui/views/edit-user-location/edit-user-location.module";
import { EditUserTimezoneModule } from "@library/ui/views/edit-user-timezone/edit-user-timezone.module";
import { PipesModule } from "@library/utils/pipes/pipes.module";
import { HomeInformationComponent } from "./home-information/home-information.component";
import { HomePageComponent } from "./home-page.component";
import { LocationViewComponent } from "./location-view/location-view.component";
import { TimezoneViewComponent } from "./timezone-view/timezone-view.component";


const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      {
        path: '',
        component: HomeInformationComponent
      },
      {
        path: 'location',
        component: LocationViewComponent
      },
      {
        path: 'timezone',
        component: TimezoneViewComponent
      },
    ]
  },
];

@NgModule({
    declarations: [
        HomeInformationComponent,
        HomePageComponent,
        LocationViewComponent,
        TimezoneViewComponent
      ],
      imports: [
        CommonModule,
        RouterModule.forChild(routes),
        PipesModule,
        UIModule,
        EditUserTimezoneModule,
        EditUserLocationModule
      ]
})
export class HomePageModule{}